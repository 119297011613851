<template>
    <div v-if="show" class="rightRoot" @click="personCardShow = false">
        <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>{{ $t('groupChatSettings') }}</span>
      </div>
      <div class="text item">
        <div class="title">{{ $t('groupId') }}:</div>
        <span>{{chatInfo.groupId}}</span> 
        <i class="el-icon-copy-document" style="margin-left:8px" @click="$copyToClipboard(chatInfo.groupId)"></i>
    </div>
      <div class="text item">
        <div class="title">{{ $t('groupName') }}:</div>
        <span>{{chatInfo.name}}</span>
      </div>
      <div class="text item">
        <div class="title">{{ $t('myGroupNickname') }}:</div>
        <el-input :placeholder="$t('myGroupNickname')" v-model="groupNickname" @keyup.native.enter="setNickName"></el-input>
      </div>
      <div class="text item">
        <div class="title">{{ $t('groupAvatar') }}:</div>
        <el-avatar :src="chatInfo.groupAvatars" size="large" style="width:100px;height:100px"/>
      </div>
      <div class="text item">
        <span>{{ $t('groupMembers') }} ({{this.members.length}}):</span>
        <div class="avatars">
          <el-popover placement="top-start" trigger="hover"
            v-for="(member, index) in members"
            :key="index" :content="member.name">
            <el-avatar
            slot="reference"
             style="cursor:pointer"
            :src="member.avatar"
            size="medium"
            shape="circle"
            @click.native.stop="getUser(member)"
          />
          </el-popover>
        </div>
      </div>
      <div class="text item">
        <span>{{ $t('groupAdministrators') }} ({{this.admins.length}}):</span>
        <div class="avatars">
          <el-popover placement="top-start" trigger="hover"
            v-for="(admin, index) in admins"
            :key="index" :content="admin.name">
            <el-avatar
            slot="reference"
             style="cursor:pointer"
            :src="admin.avatar"
            size="medium"
            shape="circle"
            @click.native.stop="getUser(admin)"
          />
          </el-popover>
        </div>
      </div>
      <div class="text item">
        <div class="title">{{ $t('groupDescription') }}:</div>
        <span>{{chatInfo.aboutGroup}}</span>
        <!-- <el-input :placeholder="$t('groupDescription')" v-model="groupDescription"></el-input> -->
      </div>
      <div class="text item">
        <div class="title">{{ $t('announcement') }}:</div>
        <span>{{chatInfo.groupAnnouncements}}</span>
        <!-- <el-input placeholder="你说123" v-model="announcement"></el-input> -->
      </div>
      <div class="text item" style="display:flex;justify-content:space-between;">
        <div class="title">{{ $t('muteNotifications') }}:</div>
        <el-switch v-model="muteNotifications"></el-switch>
      </div>
      <div class="text item">
        <el-button type="default" @click="clearMessages" style="width:100%;color:red">{{ $t('clearMessages') }}</el-button>
      </div>
    </el-card>
    <personCard style="position:absolute;bottom:100px;right:20px" :show="personCardShow" :currentUser="currentUser"></personCard>
    </div>
  </template>
  
  <script>
import { updateGroup,aliupdate } from "@/utils/api/im.js";
import personCard from "@/components/right_pop/components/personCard.vue";
export default {
  props: ["show", "chatInfo", "id"],
  components: { personCard },
  data() {
    return {
      groupName: "测试群聊",
      groupNickname: "",
      members: [],
      admins: [],
      muteNotifications: false,
      personCardShow:false,
      currentUser:{}
    };
  },
  watch: {
    show(nv) {
      if (nv) {
        this.getMember();
      }
    }
  },
  methods: {
    getUser(item){
      this.currentUser = item
      console.log(item)
      this.personCardShow = true
    },
    clearMessages() {
      this.$message({
        message: "Messages cleared",
        type: "success"
      });
    },
    setNickName() {
      aliupdate({ aliName: this.groupNickname, id: this.id }).then(r => {
        this.$hlmessage();
      });
    },
    getMember() {
      this.$getGroupMembers(this.id, 1).then(r => {
        this.admins = r;
      });
      this.$getGroupMembers(this.id, 2).then(r => {
        this.members = r;
      });
    }
  }
};
</script>
  
  <style lang="scss" scoped>
.rightRoot {
  height: 100vh;
  position: relative;
}
.box-card {
  width: 400px;
  height: 100%;
  border: none;
  overflow: auto;
}

.text.item {
  padding: 20px 0;
  .title {
    font-weight: 800;
  }
}

.avatars {
  display: flex;
  flex-wrap: wrap;
}

.avatars .el-avatar {
  margin-right: 5px;
  margin-bottom: 5px;
}
</style>
  