import Vue from 'vue'
import App from './App.vue'
import "@/utils/index"
Vue.config.productionTip = false
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import store from './store';
import router from './router'; // 引入 router
import { setTransWhenRecieve,setTransWhenSend,getTransWhenRecieve,getTransWhenSend } from "./utils/translate/index"
if(!getTransWhenRecieve()){
  setTransWhenRecieve('zh-cn')
}
if(!getTransWhenSend()){
  setTransWhenSend('en')
}
Vue.use(ElementUI);
import i18n from './locals/index';
new Vue({
  i18n,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
