<template>
    <div class="settingRoot">
      <el-row style="height:100%">
        <el-col :span="4" style="height:100%;">
          <el-menu :default-active="current" class="el-menu-vertical-demo"  @select="handleSelect">
            <el-menu-item index="1">
              <i class="el-icon-s-flag"></i>
              <span>{{ $t('language') }}</span>
            </el-menu-item>
            <el-menu-item index="2">
              <i class="el-icon-s-comment"></i>
              <span>{{ $t('textToVoice') }}</span>
            </el-menu-item>
            <el-menu-item index="3">
              <i class="el-icon-s-tools"></i>
              <span>{{ $t('automaticReply') }}</span>
            </el-menu-item>
            <el-menu-item index="4">
              <i class="el-icon-s-bell"></i>
              <span>{{ $t('messageNotification') }}</span>
            </el-menu-item>
          </el-menu>
        </el-col>
        <h5content :title="title" :current="current"></h5content>
      </el-row>
    </div>
  </template>
  
  <script>
import h5content from './components/content.vue'
export default {
  data() {
    return {
      current:"1",
      title:this.$t('language')
    };
  },
  components:{
    h5content
  },
  methods:{
    handleSelect(i){
      this.current = i
      switch(i){
        case "1":
          this.title=this.$t('language')
          break;
          case "2":
            this.title=this.$t("textto")
            break;
            case "3":
            this.title=this.$t("autoReply")
            break;
            case "4":
            this.title=this.$t("messageNoti")
            break;
      }
    }
  }
};
</script>
  
  <style lang="scss" scoped>
.settingRoot {
  // padding: 20px;
}

.el-menu-vertical-demo {
  width: 100%;
  height: 100%;
}
div ::v-deep .el-form--label-left .el-form-item__label{
  font-weight: bold;
}
div ::v-deep .el-menu-item.is-active{
  background-color: #bfe8e1;
    color: #1f8070;
    border-radius: 85px;
}
.el-menu-item{
    background: #F6F6F6;
    border-radius: 85px;
    color: #2c303b;
    font-size: 14px;
    font-weight: 700;
    margin: 0 16px;
    margin-top: 20px;
    cursor: pointer;
    height: 44px;
    display: flex;
    align-items: center;
}
</style>
  