<template>
  <div @click="contextMenuShow = false">
<div class="mesContent" v-for="(item,index) in reverseArr" :key="index"
				:style="flexdirection(item)">
        <template v-if="item.messageType == 10">
					<div style="color: grey;width: 100%;text-align:center;font-size:12px">{{item.text}}
					</div>
				</template>
        <template v-else-if="(item.messageType == 100 || item.messageType == 5) && item.attachment.raw.type == 10">
					<div style="color: grey;width: 100%;text-align:center;font-size:12px">{{item.attachment.raw.data.content}}
					</div>
				</template>
        <template v-else-if="item.attachment&&item.attachment.raw.id != undefined">
					<div style="color: grey;width: 100%;text-align:center;font-size:12px">
            {{tipList[index]}}
					</div>
				</template>
				<template v-else>
					<el-avatar :src="item.avatar" fit="cover" v-show="condition(type,item)" @click.native.stop="showPersonCard(item)"></el-avatar>
					<div v-if="item.isSelf && type == 'p2p'" style="display: flex;align-items: flex-end;">
						<img src="@/assets/readed@2x.png" mode="" style="width: 16px;height: 16px;" v-show="item.read"/>
						<img src="@/assets/noRead@2x.png" mode="" style="width: 16px;height: 16px;" v-show="!item.read"/>
					</div>
					<div class="content">
						<span class="name" v-show="condition(type,item)">{{item.fromNick}}</span>
						<div class="word" :style="border(item)" @contextmenu.prevent="customMenu($event,item)">
							<div style="max-height: 500px;padding-top: 16px;padding-bottom: 8px;">
                <!-- 文字 -->
								<span v-show="item.messageType == 0" v-html="item.text"></span>
                <!-- 自定义文字 -->
								<span
									v-if="(item.messageType == 100 || item.messageType == 5) && item.attachment.raw.type == 1">
									{{item.attachment.raw.data.content}}
								</span>
                <!-- @列表 || 翻译 -->
                 <div v-if="(item.messageType == 100 || item.messageType == 5) && item.attachment.raw.type == 66">
                  <span v-html="item.attachment.raw.data.text || item.attachment.raw"></span>
                  <div v-html="item.attachment.raw.data.customTransText" v-show="hideTrans" style="font-size:0.8em"></div>
                 </div>
                <!-- 红包 -->
								<div :style="hbflexdirection(item)"
									v-if="(item.messageType == 100 || item.messageType == 5) && item.attachment.raw.type == 52"
									@click="linghongbao(item.attachment.raw.data.id)">
									<div
										style="border-bottom: 0.02667rem solid rgba(255, 255, 255, 0.2);width: 100%;height: 120px;display: flex;align-items: center;">
										<img src="@/assets/hbb.png"
											style="height: 2.5rem;border-radius: 0;vertical-align: middle;object-fit:contain"/>
										<div style="font-size: 0.90667rem;font-weight: 600;
									color: #FFFFFF;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;flex: 1;margin: 0 32px;">
											{{item.attachment.raw.title}}
										</div>
									</div>
									<span
										style="font-size: 0.48rem;font-weight: 500;color: rgba(255, 255, 255, 0.8);">Redpocket</span>
								</div>
                <!-- 宝箱 -->
								<img @click="baoxiang(item.attachment.raw.data)"
									:src="require('@/assets/treasure.png')"
									v-if="(item.messageType == 100 || item.messageType == 5) && item.attachment.raw.type == 53"
									mode=""/>
                  <!-- 自定义图片 -->
								<div
									v-if="(item.messageType == 100 || item.messageType == 5) && item.attachment.raw.type == 6"
									@click="downLoad(item.attachment.raw.data.url)">
									<div style="display: flex;justify-content: center;align-items: center;">
										<img src="@/assets/files.png" style="width: 200px;" mode="widthFix"/>
									</div>
									<div
										style="overflow: hidden;span-overflow: ellipsis;white-space: nowrap;span-align: center;">
										File
									</div>
								</div>
								<span v-show="item.messageType == 5">{{item.text}}</span>
                <!-- 图片 -->
                <el-image :src="item.attachment&&item.attachment.url" v-show="item.messageType == 1"
									style="width: 100%;border-radius: 0;" :preview-src-list="srcList" @click.native="srcList = [item.attachment.url]"></el-image>
                  <!-- 视频 -->
								<video :src="item.attachment&&item.attachment.url" v-show="item.messageType == 3"
									style="width: 11.84rem;border-radius: 0;object-fit: contain;"></video>
							</div>
							<div style="color: #C3C3C3;font-size: 0.6rem;align-self: flex-end;">
								{{$formateDate(item.time)}}
							</div>
						</div>
					</div>
					<el-avatar :src="user.avatar" fit="cover" v-show="item.isSelf"></el-avatar>
				</template>
			</div>
      <redpocetCard :show.sync="RedPacketCardShow" :id="redPocketId"></redpocetCard>
      <recievePocket :show.sync="popupVisible" :id="redPocketId" @views="RedPacketCardShow=true"></recievePocket>
      <personCard :show.sync="personCardShow" :currentUser="currentUser" style="position: fixed;top:0; height: 100%;width: calc(100% - 362px);display: flex;
      justify-content: center;align-items: center;"></personCard>
      <Treasure :show.sync="TreasurePopupShow" :id="baoxiangid"></Treasure>
      <receiveBaoxiang :show.sync="receiveBaoxiangShow" :query="currenQuery"></receiveBaoxiang>
      <contextMenu v-if="contextMenuShow" :customMenuProp="customMenuProp" @Recall="Recall" @Reply="Reply"></contextMenu>
    </div>
</template>
<script>
import { getLocalUser } from "@/utils/index.js";
import { redpck, treasure } from "@/utils/api/im.js";
import redpocetCard from "./redpocket/redpocetCard";
import recievePocket from "./redpocket/recievePocket";
import personCard from "@/components/right_pop/components/personCard.vue";
import Treasure from "@/components/chatinfo/components/baoxiang/Treasure.vue";
import receiveBaoxiang from "@/components/chatinfo/components/baoxiang/receiveBaoxiang.vue";
import contextMenu from "@/components/chatinfo/components/contextMenu/index.vue";
export default {
  props: ["reverseArr", "type", "hideTrans"],
  components: {
    redpocetCard,
    recievePocket,
    personCard,
    Treasure,
    receiveBaoxiang,
    contextMenu
  },
  data() {
    return {
      user: getLocalUser(),
      popupVisible: false,
      RedPacketCardShow: false,
      redPocketId: "",
      srcList: [],
      personCardShow: false,
      currentUser: {},
      baoxiangid: "",
      TreasurePopupShow: false,
      receiveBaoxiangShow: false,
      currenQuery: {},
      // 自定义右键菜单
      contextMenuShow: false,
      customMenuProp: {},
      tipList: [],
      scrollTop: 0
    };
  },
  watch: {
    reverseArr(n) {
      this.tipList = [];
      for (let i in n) {
        this.tipes(n[i], i);
      }
      this.$nextTick(() => {
        setTimeout(() => {
          this.scrollTop = 9999;
        }, 100);
      });
    }
  },
  methods: {
    Reply(e) {
      this.$emit("Reply", e);
    },
    customMenu(e, item) {
      let { pageX, pageY } = e;
      this.contextMenuShow = false;
      this.$nextTick(() => {
        this.customMenuProp.item = item;
        this.customMenuProp.target = e;
        this.customMenuProp.left = pageX;
        this.customMenuProp.top = pageY;
        this.contextMenuShow = true;
      });
    },
    Recall(e) {
      console.log(e);
      this.$im.V2NIMMessageService.revokeMessage(e)
        .then(r => {
          this.$message({ type: "success", message: this.$t("success") });
        })
        .catch(r => {
          this.$message({ type: "error", message: this.$t("error") });
        });
    },
    baoxiang(e) {
      treasure(e.id).then(r => {
        console.log(r);
        let query = r;
        if (!query.recordVoList) {
          query.recordVoList = [];
        }
        let q = query.recordVoList.find(r => {
          return r.uid == getLocalUser().imAccountId;
        });
        let finish = r.useAmount == r.totalAmount;
        this.baoxiangid = e.id;
        if (!(q && finish)) {
          this.TreasurePopupShow = true;
        } else {
          this.currenQuery = r;
          this.currenQuery.currentScore = q.amount;
          this.receiveBaoxiangShow = true;
        }
      });
    },
    showPersonCard(e) {
      this.$getUser(e.senderId).then(r => {
        this.currentUser = r[0];
        this.personCardShow = true;
      });
    },
    linghongbao(id) {
      redpck(id).then(r => {
        console.log(r);
        let query = r;
        if (!query.recordVoList) {
          query.recordVoList = [];
        }
        let q = query.recordVoList.find(r => {
          return r.uid == getLocalUser().imAccountId;
        });
        let finish = r.useAmount == r.totalAmount;
        this.redPocketId = id;
        if (!(q && finish)) {
          this.popupVisible = true;
        } else {
          this.RedPacketCardShow = true;
        }
      });
    },
    tipes(item, i) {
      if (item.attachment) {
        if (item.attachment.raw.id == 0 && item.attachment.raw.data.ids) {
          let q = this.$t("welcome") + " ";
          if (item.attachment.raw.data.ids.length != 0) {
            let arr = [];
            this.$getUser(...item.attachment.raw.data.ids).then(r => {
              arr = r;
              for (let i in arr) {
                q = q + arr[i].name;
              }
              q = q + " " + this.$t("join");
              this.$set(this.tipList, i, q);
            });
          }
        }
        if ((item.attachment.raw.id == 1 || item.attachment.raw.id == 2)&&item.attachment.raw.data.ids) {
          let q = "";
          if (item.attachment.raw.data.ids.length != 0) {
            let arr = [];
            this.$getUser(...item.attachment.raw.data.ids).then(r => {
              arr = r;
              for (let i in arr) {
                q = q + arr[i].name;
              }
              q = q + " " + this.$t("leftGroup");
              this.$set(this.tipList, i, q);
            });
          }
        }
        if (item.attachment.raw.id == 3) {
          let q = this.$t("updateInfo");
          this.$set(this.tipList, i, q);
        }
        if (item.attachment.raw.id == 4) {
          let q = this.$t("Groupdissolution");
          this.$set(this.tipList, i, q);
        }
        if (item.attachment.raw.id == 5) {
          let q = this.$t("Groupapplicationsuccessfullyjoined");
          this.$set(this.tipList, i, q);
        }
        if (item.attachment.raw.id == 6) {
          let q = this.$t("groupowner");
          this.$set(this.tipList, i, q);
        }
        if (item.attachment.raw.id == 7) {
          let q = this.$t("Addadministrator");
          this.$set(this.tipList, i, q);
        }
        if (item.attachment.raw.id == 8) {
          let q = this.$t("DeleteAdministrator");
          this.$set(this.tipList, i, q);
        }
      }
    },
    condition(type, item) {
      if (type == "team") {
        return !item.isSelf;
      }
      if (type == "p2p") {
        return !item.isSelf;
      }
      return false;
    },
    flexdirection(e) {
      if (this.type == "p2p") {
        if (e.isSelf) {
          return "justify-content:flex-end;";
        } else return "";
      }
      if (this.type == "team") {
        if (e.isSelf) {
          return "justify-content:flex-end;";
        } else return "";
      }
    },
    hbflexdirection(e) {
      let hb = `display:flex;flex-direction: column;width: 10rem;height: 5rem;background: url(${require("@/assets/hbbg.png")});background-position: center;padding: 8px 16px;`;
      let hbCondition =
        (e.messageType == 100 || e.messageType == 5) &&
        e.attachment.raw.type == 52;
      let flexDireciton = `justify-content:flex-end;`;
      if (this.type == "p2p") {
        if (e.isSelf) {
          if (hbCondition) return hb + flexDireciton;
          return flexDireciton;
        } else {
          if (hbCondition) return hb;
        }
      }
      if (this.type == "team") {
        if (e.isSelf) {
          if (hbCondition) return hb + flexDireciton;
          return flexDireciton;
        } else {
          if (hbCondition) return hb;
        }
      }
    },
    border(e) {
      let q = "border-radius: 0.88rem 0 0.88rem 0.88rem;";
      let p = "border-radius: 0 0.88rem 0.88rem 0.88rem;";
      let t = "";
      if (e.messageType == 100 && e.attachment.raw.type == 53) {
        t = "background:transparent;";
      }
      if (this.type == "p2p") {
        if (e.isSelf) {
          return q + t;
        } else return p + t;
      }
      if (this.type == "team") {
        if (e.isSelf) {
          return q + t;
        } else return p + t;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.mesContent {
  display: flex;
  padding: 32px 8px;
  //   .aiteList{
  //   color: #3669de;
  //   margin:0 3px;
  // }
  img {
    height: 160px;
    width: 160px;
    border-radius: 50%;
  }

  .content {
    display: flex;
    flex-direction: column;
    margin: 0 24px;

    .name {
      font-size: 0.74667rem;
      font-family: SF Pro Display-Medium, SF Pro Display;
      font-weight: 500;
      color: #666;
    }

    .word {
      margin-top: 16px;
      padding: 0 16px 8px 16px;
      max-width: 11.84rem;
      min-width: 5.33333rem;
      background: #ffffff;
      box-shadow: 0 0.21333rem 1.6rem 0 rgba(0, 0, 0, 0.1);
      color: #4a4a4a;
      line-height: 0.96rem;
      span-align: left;
      position: relative;
      word-wrap: break-word;
      font-size: 0.85333rem;
      display: flex;
      flex-direction: column;
      cursor: pointer;
    }
  }
}
.el-avatar > img {
  width: 100%;
}
</style>
