<template>
    <div class="popup-container" v-if="show">
      <div class="card">
        <div style="flex-basis:45%">
            <div class="header">{{query.textInfo}}</div>
        <div class="message">You've received a gift from {{ query.nickName }}
            <img :src="query.userAvatar" class="avatar" style="vertical-align:middle">
        </div>
        <div class="score">{{ query.currentScore }} <span>{{$t('Score')}}</span></div>
        </div>
        <div class="ranklist">
        <div class="tip">{{$t('claimed')}} {{ query.useNum }}/{{ query.packetNum }}, {{$t('total')}} {{ query.useAmount }}/{{query.totalAmount}} {{$t('Score')}}</div>
        <div class="item" v-for="(item,index) in query.recordVoList" :key="index">
            <img :src="item.userAvatar" alt="avatar" class="avatar"/>
          <div class="info">
            <div class="username">{{ item.userName }}</div>
            <div class="user-score">{{ item.amount }} {{$t('Score')}}</div>
          </div>
        </div>
        </div>
      </div>
      <img src="@/assets/close_border@2x.png" class="close-button" @click="closePopup"/>
    </div>
  </template>
  
  <script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    query:{}
  },
  methods: {
    closePopup() {
      this.$emit("update:show", false);
    }
  }
};
</script>
  
  <style scoped>
.popup-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  color: #ffd700;
}

.card {
  width: 345px;
  height: 540px;
  background: url("@/assets/result@2x.png");
  background-size: contain;
  background-repeat: no-repeat;
  padding: 20px;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
}

.header {
  font-size: 24px;
  font-weight: bold;
  color:#feeda6;
  margin: 30px 0 20px;
}

.message {
  font-size: 16px;
  margin-bottom: 20px;
  color:#feeda6;
}

.score {
  font-size: 48px;
}

.score span {
  font-size: 24px;
}

.tip {
  font-size: 14px;
  margin-bottom: 20px;
  color:#feeda6;
  text-align: left;
}

.ranklist {
  margin-bottom: 20px;
  flex: 1
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.info {
  display: flex;
  flex:1;
  justify-content: space-between;
}

.username {
  font-size: 16px;
  font-weight: bold;
  color: #f6e9cb;
}

.user-score {
  font-size: 14px;
  color: #f6e9cb;
}

.close-button {
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin-top: 30px;
}
.item{
    display: flex;
    align-items: center
}
</style>
  