<template>
    <div class="pictureRoot">
      <el-row>
        <el-col :span="10">
            <div class="form-section">
          <h3>{{ $t('pictureViewer') }}</h3>
          <p>{{ $t('pictureViewerDescription') }}</p>
          <el-input :placeholder="$t('imageLinkPlaceholder')" v-model="imageLink" clearable />
          <el-image :src="imageLink" v-if="imageLink" style="margin-top:32px">
            <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
           </div>
          </el-image>
        </div>
        
        <div class="form-section">
          <h3>{{ $t('textDecryption') }}</h3>
          <p>{{ $t('textDecryptionDescription') }}</p>
          <el-input :placeholder="$t('textDecryptionPlaceholder')" v-model="textToDecrypt" clearable />
          <span v-if="textToDecrypt">{{textToDecrypt}}</span>
        </div>
        
        <div class="form-section">
          <h3>{{ $t('textEncryption') }}</h3>
          <p>{{ $t('textEncryptionDescription') }}</p>
          <el-input :placeholder="$t('textEncryptionPlaceholder')" v-model="textToEncrypt" clearable />
          <span v-if="textToEncrypt">{{textToEncrypt}}</span>
        </div>
        </el-col>
      </el-row>
    </div>
  </template>
  
  <script>
export default {
  data() {
    return {
      imageLink: "",
      textToDecrypt: "",
      textToEncrypt: ""
    };
  }
};
</script>
  
  <style>
.pictureRoot {
  padding: 20px;
}

.form-section {
  margin-bottom: 30px;
}

.form-section h3 {
  margin-bottom: 10px;
}

.form-section p {
  margin-bottom: 10px;
  color: grey;
}
</style>
  