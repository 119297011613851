<template>
  <router-view></router-view>
</template>

<script>
import  uni from "./utils/bus"
export default {
  name: 'App',
  mounted() {
			if (localStorage.getItem('blink')) {
				uni.$on('onReceiveMessages', () => {
					this.startScrollingTitle("You have new messages...");
				})
				uni.$on('clearWebTitle', () => {
					this.startScrollingTitle();
				})
			}
			if (localStorage.getItem('sound')) {
				uni.$on('onReceiveMessages', () => {
					const audio = new Audio(require('./assets/notice.e0d6b0b.mp3'));
					audio.play();
				})
			}
		},
		methods: {
			startScrollingTitle(t) {
				let title = t
				let i = 0;
				setInterval(() => {
					document.getElementsByTagName('title')[0].innerText = title.slice(i) + ' ' + title.slice(0, i);
					i = (i + 1) % title.length;
				}, 100);
			},
		}
}
</script>

<style>

</style>
