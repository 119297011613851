import Vue from "vue"
import NIM from 'nim-web-sdk-ng/dist/v2/NIM_BROWSER_SDK'
import { getLocalUser } from ".."
import uni from "../bus"
const nim = NIM.getInstance({
	appkey: "960dbbfc6d155e1097b77687ac5a41f6",
	apiVersion: "v2"
}, {
	lbsUrls: [
		"https://lbs.netease.im/lbs/wxwebconf.jsp"
	],
	linkUrl: "wlnimsc0.netease.im"
},)

nim.V2NIMLoginService.on("onConnectStatus", function (status) {
	console.log(status)
})

const eventList = [
	'users'
]
let friends = []
let teams = []
eventList.forEach((key) => {
	nim.on(key, (res) => {
		if (key == "users" && res) {
			friends = JSON.parse(JSON.stringify(res))
			uni.$emit('getFriends', friends)
		}
		console.log(`Receive ${key} event：`, res ? JSON.parse(JSON.stringify(res)) : res);
	});
})
// 会话变更以及同步回调
nim.V2NIMConversationService.on("onSyncFinished", function () {
	uni.$emit('getSessions')
})
// nim.V2NIMConversationService.on("onConversationChanged", function () {
// 	uni.$emit('getSessions')
// })
// 群组同步回调
nim.V2NIMTeamService.on('onSyncFinished', () => {
	uni.$emit('getTeams')
})
nim.V2NIMFriendService.on("onFriendInfoChanged", function (friend) {
	uni.$emit('onFriendInfoChanged', friend)
})
// then，receive event 'logined'
nim.V2NIMMessageService.on("onReceiveMessages", function (messages) {
	uni.$emit('recieveMes', messages[0])
	// console.log('message',messages)
})
// 消息撤回
nim.V2NIMMessageService.on("onMessageRevokeNotifications", function(revokeNotifications) {
	uni.$emit('onMessageRevokeNotifications', revokeNotifications[0].messageRefer)
})
nim.V2NIMConversationService.on("onTotalUnreadCountChanged", function (unreadCount) {
	uni.$emit('unreadCount', unreadCount)
})
// nim.connect();
async function sendRead(e) {
	return new Promise((r, i) => {
		console.log(e)
		nim.V2NIMMessageService.sendP2PMessageReceipt(e).then(a => {
			r(a)
		}).catch(er => {
			i(er)
		})
	})
}
async function eidtGroup(id, query) {
	query.updateInfoMode = 1
	console.log(query)
	return new Promise((r, i) => {
		nim.V2NIMTeamService.updateTeamInfo(
			id,
			1, query
		).then(a => {
			r(a)
		}).catch(er => {
			i(er)
		})
	})
}

async function setFriendInfo(id, alias) {
	return new Promise((r, i) => {
		nim.V2NIMFriendService.setFriendInfo(id, {
			alias: alias,
		}).then(a => {
			r(a)
		}).catch(er => {
			i(er)
		})
	})
}

function getUser(d) {
	return new Promise((r, i) => {
		nim.V2NIMUserService.getUserList([d]).then(a => {
			r(a)
		}).catch(j => {
			i(j)
			console.log('rrrrrrrrrr,,,,', j)
		})
	})
}
async function getCloudSession() {
	return new Promise((res, rej) => {
		nim.V2NIMConversationService.getConversationList(0, 100).then(r => {
			console.log(r)
			res(r.conversationList)
		})
	})
}
async function clearCloudSessionUnread(s) {
	return new Promise((res, rej) => {
		nim.V2NIMConversationService.clearUnreadCountByIds([s]).then(r => {
			res(r)
		}).catch(r => {
			rej(r)
		})
	})
}

function getHis(to, scene = "p2p", limit = 100, beginTime) {
	let q;
	if (scene == 'p2p')
		q = nim.V2NIMConversationIdUtil.p2pConversationId(to)
	else
		q = nim.V2NIMConversationIdUtil.teamConversationId(to)
	return new Promise((i, r) => {
		nim.V2NIMMessageService.getMessageList({
			"conversationId": q,
			"limit": limit,
			beginTime
		}).then(a => {
			i(a)
		}).catch(e => {
			r(e)
		})
	})
}

function clear(to, scene = "p2p") {
	let q;
	if (scene == 'p2p')
		q = nim.V2NIMConversationIdUtil.p2pConversationId(to)
	else
		q = nim.V2NIMConversationIdUtil.teamConversationId(to)
	return new Promise((i, r) => {
		nim.V2NIMMessageService.clearHistoryMessage({ conversationId: q }).then(a => {
			i(a)
		}).catch(e => {
			r(e)
		})
	})
}

function getFriends() {
	return new Promise((i, r) => {
		nim.V2NIMFriendService.getFriendList().then(a => {
			let arr = []
			for (let ii in a) {
				arr.push(a[ii].accountId)
			}
			if (arr.length != 0)
				nim.V2NIMFriendService.getFriendByIds(arr).then((re, rj) => {
					i(re)
				})
		}).catch(e => {
			r(e)
		})
	})
}
function getFriendByIds(id) {
	return new Promise((i, r) => {
		nim.V2NIMFriendService.getFriendByIds([id]).then(a => {
			i(a)
		}).catch(e => {
			r(e)
		})
	})
}

function getTeams() {
	return new Promise((i, r) => {
		nim.V2NIMTeamService.getJoinedTeamList().then(a => {
			i(a)
		}).catch(e => {
			r(e)
		})
	})
}

function sendMessage(message,q,cb){
	nim.V2NIMMessageService.sendMessage(message, q).then(r => {
		cb(r)
	}).catch(r => {
		this.$message.error('failed')
	})
}

async function sendMes(methods = "sendTextMsg", scene, to, body, cb = () => { }, file) {
	let q;
	if (scene == 'p2p')
		q = nim.V2NIMConversationIdUtil.p2pConversationId(to)
	else
		q = nim.V2NIMConversationIdUtil.teamConversationId(to)
	switch (methods) {
		case "sendTextMsg": {
			const message = nim.V2NIMMessageCreator.createTextMessage(body)
			sendMessage(message,q,cb)
			break;
		}
		case "sendTipMsg": {
			const message = nim.V2NIMMessageCreator.createTipsMessage(body)
			console.log(message)
			sendMessage(message,q,cb)
			break;
		}
		case "sendCustomMsg": {
			const message = nim.V2NIMMessageCreator.createCustomMessage("text",JSON.stringify(body))
			console.log(message)
			sendMessage(message,q,cb)
			break;
		}
		case "sendGeoLocationMsg": {
			await nim.msg.sendGeoLocationMsg({
				"scene": scene,
				"to": to,
				// JSON object
				"attach": {
					"lng": 120.18201069999999,
					"lat": 30.2006603,
					"title": "Hangzhou"
				}
			})
			break;
		}
		case "sendImageMsg": {
			const message = nim.V2NIMMessageCreator.createImageMessage(file)
			sendMessage(message,q,cb)
			break;
		}
		case "sendAudioMsg": {
			const message = nim.V2NIMMessageCreator.createAudioMessage(file)
			sendMessage(message,q,cb)
			break;
		}
		case "sendVideoMsg": {
			const message = nim.V2NIMMessageCreator.createVideoMessage(file)
			sendMessage(message,q,cb)
			break;
		}
		case "sendFileMsg": {
			const message = nim.V2NIMMessageCreator.createFileMessage(file)
			sendMessage(message,q,cb)
			break;
		}
		default: {
			console.error('this mes is not supported!')
			return
		}
	}
}

async function getGroupMembers(id, roleQueryType = 0) {
	return new Promise((res, rej) => {
		nim.V2NIMTeamService.getTeamMemberList(id, 1, {
			"roleQueryType": roleQueryType,
			"onlyChatBanned": false,
			"direction": 0,
			"limit": 100,
			"nextToken": ""
		}).then(c => {
			console.log('c.memberList', c.memberList)
			let arr = []
			for (let i in c.memberList) {
				arr.push(c.memberList[i].accountId)
			}
			nim.V2NIMUserService.getUserList([...arr]).then(r => {
				res(r)
			})
		})
	})
}

Vue.prototype.$sendMes = sendMes
Vue.prototype.$getGroupMembers = getGroupMembers
Vue.prototype.$getHis = getHis
Vue.prototype.$getFriends = getFriends
Vue.prototype.$getFriendByIds = getFriendByIds
Vue.prototype.$getTeams = getTeams
Vue.prototype.$getUser = getUser
Vue.prototype.$sendRead = sendRead
Vue.prototype.$getCloudSession = getCloudSession
Vue.prototype.$eidtGroup = eidtGroup
Vue.prototype.$setFriendInfo = setFriendInfo
Vue.prototype.$clearCloudSessionUnread = clearCloudSessionUnread
Vue.prototype.$clear = clear
Vue.prototype.$im = nim