<template>
  <div class="indexRoot">
    <div class="leftBtn" v-if="showBtn">
      <el-popover placement="bottom" width="200" trigger="click">
        <div v-for="(item, index) in popOverList" :key="index" style="margin: 8px 0;cursor: pointer;">
          <span @click="popOverItemClick(item)">{{ item.label }}</span>
          <span style="margin: 0 3px">{{ item.content }}</span>
          <i class="el-icon-document-copy" v-show="item.value == 'copy'" @click="$copyToClipboard(item.content)"></i>
          <i class="el-icon-edit-outline" v-show="item.value == 'edit'" @click="eidt(item)"></i>
        </div>
        <img :src="user.avatar" class="usericon" alt="" slot="reference">
      </el-popover>
      <div class="item" v-for="(item, index) in btn" :key="index" @click="current = index">
        <div v-if="current == index" class="activeLeft"></div>
        <img :src="current == index ? item.activeImg : item.img" alt="">
        <span :class="{ active: current == index }">{{ item.title }}</span>
      </div>
    </div>
    <div class="folder" @click="showBtn = !showBtn">
      <i class="el-icon-s-fold" v-if="showBtn"></i>
      <i class="el-icon-s-unfold" v-if="!showBtn"></i>
    </div>
    <keep-alive>
      <HelloWorld style="flex:1" v-if="current === 0"></HelloWorld>
    </keep-alive>
    <group v-if="current === 1"></group>
    <custom v-if="current === 2"></custom>
    <h5picture v-if="current === 3" style="flex:1"></h5picture>
    <!-- <request v-if="current === 4" style="flex:1"></request> -->
    <setting v-if="current === 4" style="flex:1"></setting>
    <el-dialog :visible.sync="dialogVisible" width="width">
      <el-input v-model="user.userName" placeholder=""></el-input>
      <div slot="footer">
        <el-button @click="dialogVisible = false">{{ $t('cancel') }}</el-button>
        <el-button type="primary" @click="confirm">{{ $t('confirm') }}</el-button>
      </div>
    </el-dialog>
    <input type="file" v-show="false" ref="file" accept="image/*" @change="avatar" />
  </div>
</template>
<script>
import "@/utils/im"
var a = "_active";
var b = "btn/";
import custom from "@/components/left_pop/custom.vue";
import group from "@/components/left_pop/group.vue";
import h5picture from "@/components/picture/index.vue";
import request from "@/components/request/index.vue";
import setting from "@/components/setting/index.vue";
import HelloWorld from "@/components/chatinfo/HelloWorld.vue";
import { getUser, updateUser } from "@/utils/api/user.js";
import { upload } from "@/utils/api/im"
import { setUser, setToken } from "@/utils/index.js";
import uni from "@/utils/bus";
export default {
  components: {
    HelloWorld,
    custom,
    group,
    h5picture,
    request,
    setting
  },
  data() {
    return {
      current: 0,
      showBtn: true,
      user: {
        avatar: ""
      },
      dialogVisible: false,
      popOverList: [
        { label: "ID", value: "copy" },
        { label: this.$t("account"), value: "copy" },
        { label: this.$t("nickName"), value: "edit" },
        { label: this.$t("updateAvatar"), value: "edit" },
        { label: this.$t("logOut") }
      ],
      btn: [
        {
          title: this.$t("Message"),
          activeImg: this.$getImg(`${b}chat${a}.png`),
          img: this.$getImg(`${b}chat.png`)
        },
        {
          title: this.$t("Group"),
          activeImg: this.$getImg(`${b}user_group${a}.png`),
          img: this.$getImg(`${b}user_group.png`)
        },
        {
          title: this.$t("Friend"),
          activeImg: this.$getImg(`${b}footertab${a}.png`),
          img: this.$getImg(`${b}footertab.png`)
        },
        {
          title: this.$t("Picture"),
          activeImg: this.$getImg(`${b}find${a}.png`),
          img: this.$getImg(`${b}find.png`)
        },
        // {
        //   title: this.$t("Request"),
        //   activeImg: this.$getImg(`${b}request${a}.png`),
        //   img: this.$getImg(`${b}request.png`)
        // },
        {
          title: this.$t("Setting"),
          activeImg: this.$getImg(`${b}setting${a}.png`),
          img: this.$getImg(`${b}setting.png`)
        }
      ]
    };
  },
  created() {
    this.getUser();
    uni.$on("session", () => {
      this.current = 0;
    });
  },
  watch: {
    current(n) {
      if (n == 0) {
        uni.$emit("getSessions");
      }
    }
  },
  methods: {
    popOverItemClick(item) {
      if (item.label == this.$t("logOut")) {
        setUser({})
        setToken()
        window.location.href = "/"
      }
    },
    avatar(e) {
      upload(e.target.files[0]).then(r => {
        updateUser({ avatar: r }).then(r => {
          this.$message({ type: "success", message: this.$t('success') })
          this.getUser()
        })
      })
    },
    confirm() {
      updateUser({ nickName: this.user.userName }).then(r => {
        this.$message({ type: "success", message: this.$t('success') })
        this.dialogVisible = false
        this.getUser()
      })
    },
    eidt(item) {
      if (item.label == this.$t("nickName")) {
        this.dialogVisible = true
      }
      else {
        this.$refs.file.click()
      }
    },
    getUser() {
      getUser().then(r => {
        this.user = r;
        console.log(this.user)
        this.$set(this.popOverList[0], "content", this.user.uuid)
        this.$set(this.popOverList[1], "content", this.user.imAccountId)
        this.$set(this.popOverList[2], "content", this.user.userName)
        setUser(this.user);
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.indexRoot {
  display: flex;
}

.folder {
  width: 40px;
  height: 38px;
  color: #868686;
  font-size: 30px;
  position: fixed;
  bottom: 10px;
  left: 12px;
  z-index: 2;
  cursor: pointer;
}

.leftBtn {
  width: 68px;
  height: 100vh;
  margin-left: 0;
  background-color: #292f4f;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .usericon {
    width: 48px;
    height: 48px;
    position: relative;
    margin: 40px 0;
    cursor: pointer;
    border-radius: 50%;
  }

  .item {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    color: rgb(106, 110, 132);
    font-size: 12px;
    padding: 8px 0;
    cursor: pointer;
    position: relative;

    img {
      width: 35px;
      height: 35px;
      margin-bottom: 4px;
    }

    .active {
      color: rgb(24, 216, 175);
    }

    .activeLeft {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 20px;
      background-image: linear-gradient(to right,
          rgba(28, 213, 180, 0.4),
          rgba(39, 85, 102, 0.1));
      border-left: 5px solid #15daac;
    }
  }
}
</style>
