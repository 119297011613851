<template>
                <div class="notification-settings">
      <el-row class="notification-item">
        <el-col :span="22">
          <span>{{ $t('soundNotification') }}</span>
        </el-col>
        <el-col :span="2">
          <el-switch v-model="soundNotification"></el-switch>
        </el-col>
      </el-row>
      <el-row class="notification-item">
        <el-col :span="22">
          <span>{{ $t('browserBlinkNotification') }}</span>
        </el-col>
        <el-col :span="2">
          <el-switch v-model="browserBlinkNotification"></el-switch>
        </el-col>
      </el-row>
    </div>
</template>
<script>
export default {
  data() {
    return {
      soundNotification: false,
      browserBlinkNotification: false
    };
  },
  created(){
    if(JSON.parse(localStorage.getItem('sound')))
			this.soundNotification = true
			if(JSON.parse(localStorage.getItem('blink')))
			this.browserBlinkNotification = true
  },
  watch:{
    soundNotification(v){
				localStorage.setItem('sound',v)
			},
			browserBlinkNotification(v){
				localStorage.setItem('blink',v)
			}
		}
};
</script>
<style lang="scss" scoped>
.notification-settings {
  padding: 20px;
}

.notification-item {
  width: 45%;
    height: 40px;
    background: #FFFFFF;
    border-radius: 12px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    justify-content: space-between;
    border: 1.4px solid #CFD5DE;
    margin-bottom: 20px;
}

.notification-item span {
  font-size: 16px;
}
</style>

