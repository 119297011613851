<template>
    <div class="at-list" v-if="show">
      <el-input v-model="searchQuery" placeholder="Search" clearable></el-input>
      <div class="el-list">
        <div class="el-list-item" v-for="user in filteredUsers" :key="user.id" @click="$emit('aite',{id:user.accountId,name:user.name})">
          <el-avatar :src="user.avatar" />
          <span>{{ user.name }}</span>
        </div>
    </div>
    </div>
  </template>
  
  <script>
import mixin from "@/mixin/index.js";
export default {
  data() {
    return {
      searchQuery: "",
      users: [{ name: " " }]
    };
  },
  mixins: [mixin],
  methods: {
    getMem() {
      this.$getGroupMembers(this.id).then(r=>{
        this.users = r
      });
    }
  },
  props: ["show", "id"],
  watch: {
    show(nv) {
      if (nv) this.getMem();
    }
  }
};
</script>
  
  <style>
.at-list {
  position: absolute;
  bottom: 50px;
  z-index: 1001;
  padding: 10px;
  width: 500px;
  background: #fff;
  text-align: left;
  overflow: auto;
  cursor: pointer;
  border-radius: 10px 10px 0 0;
  box-shadow: 0 0 5px 5px #0000001a;
  box-sizing: border-box;
}

.el-list {
  max-height: 200px;
  overflow-y: auto;
}

.el-list-item {
  display: flex;
  align-items: center;
  padding: 10px;
}
.el-list-item:hover {
  background-color: #d1d1d1;
}

.el-list-item span {
  margin-left: 10px;
}
</style>
  