import Vue from 'vue';
import Router from 'vue-router';
import Login from '@/views/login.vue';
import index from '@/views/index.vue';
Vue.use(Router);

export default new Router({
    mode: 'hash',
    routes: [
        {
            path: '/',
            redirect: "/login"
        },
        {
            path: '/index',
            name: 'index',
            component: index
        },
        {
            path: '/login',
            name: 'Login',
            component: Login
        }
    ]
});
