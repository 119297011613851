<template>
    <div class="red-envelope-container" v-if="visible">
      <div class="red-envelope">
        <div class="redPocketContent">
        <div class="inputContent">
            <el-select v-model="amountType" placeholder="Select" style="flex:1;margin-right:15px">
          <el-option :label="$t('RandomAmount')" :value="1"></el-option>
          <el-option :label="$t('identicalAmount')" :value="2"></el-option>
        </el-select>
    </div>
        <div class="inputContent">
            <span>{{$t('Quantity')}}</span>
            <el-input v-model="quantity" type="number" :min="1" placeholder="Quantity" class="full-width">
            </el-input>
        </div>
        <div class="redTip">
        This group in total 125 people
        </div>
        <div class="inputContent">
            <span>{{$t('total')}}</span>
            <el-input v-model="total" type="number" :min="1" placeholder="Total" class="full-width">
            </el-input>
        </div>
        <div class="inputContent" style="padding-right:15px;padding-left:0">
        <el-input v-model="message" placeholder="Best wishes" class="full-width" style="text-align:left">
        </el-input>
        <el-upload
        style="height:100%"
        action=""
  :show-file-list="false"
  :on-success="handleAvatarSuccess"
  :before-upload="beforeAvatarUpload">
  <img  :src="img?img:require('@/assets/emoji.png')" style="height:100%"/>
</el-upload>
        </div>
        <div class="points">{{ points }} Points</div>
        <el-button type="danger" class="send-button" @click="sendRedEnvelope">{{ $t('send') }}</el-button>
        </div>
      </div>
      <div class="overlay" @click="close"></div>
    </div>
  </template>
  
  <script>
import { upload,sendPocket } from "@/utils/api/im.js";
export default {
  props: ["visible","id","type"],
  data() {
    return {
      amountType: 1,
      quantity: 1,
      total: 0,
      message: "Best wishes",
      points: 0,
      img: undefined
    };
  },
  watch: {
    amountType: {
      handler(nv) {
        if (nv == 1) {
          // 手气红包
          this.points = this.total;
        }
        if (nv == 2) {
          this.points = this.quantity * this.total;
        }
      },
      immediate: true
    },
    total: {
      handler(nv) {
        if (this.amountType == 1) {
          // 手气红包
          this.points = this.total;
        }
        if (this.amountType == 2) {
          this.points = this.quantity * this.total;
        }
      },
      immediate: true
    },
    quantity: {
      handler(nv) {
        if (this.amountType == 1) {
          // 手气红包
          this.points = this.total;
        }
        if (this.amountType == 2) {
          this.points = this.quantity * this.total;
        }
      },
      immediate: true
    }
  },
  methods: {
    handleAvatarSuccess() {},
    beforeAvatarUpload(e) {
      upload(e).then(r => {
        this.img = r
      });
      return false;
    },
    close() {
      this.$emit("update:visible", false);
    },
    sendRedEnvelope() {
      // 发送红包逻辑
      console.log("Send red envelope", {
        amountType: this.amountType,
        quantity: this.quantity,
        total: this.total,
        message: this.message
      });
      let query = {
        amount:this.points,
        type:this.amountType,
        totalPacket:this.quantity,
        title:this.message,
        conversationType:this.type == 'team'?1:0,
        targetId:this.id,
        img:this.img
      }
      sendPocket(query).then(r=>{
        this.close();
      })
    }
  }
};
</script>
  
  <style scoped>
.red-envelope-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.red-envelope {
  position: relative;
  width: 300px;
  background: #fff;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  text-align: center;
  z-index: 1001;
  padding: 82px 18px 18px;
  position: relative;
  background: url("../../../../assets/send_redpacket@2x.png") no-repeat top left /
    100% 144px #fd3c51;
  border-radius: 12px;
}
.full-width {
  flex: 1;
  text-align: right;
}
.full-width ::v-deep .el-input__inner {
  border: none;
  background: transparent;
  text-align: inherit;
}

.points {
  font-size: 24px;
  margin: 20px 0;
}

.send-button {
  width: 100%;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}
.redPocketContent {
  padding: 10px 10px 24px;
  background: #ffffff;
  border-radius: 12px;
}
.inputContent {
  display: flex;
  align-items: center;
  padding: 10px 0 10px 15px;
  height: 40px;
  box-sizing: border-box;
  background: #f6f6f6;
  border-radius: 5px;
  margin-top: 10px;
}
.redTip {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 10px;
  color: #999;
  margin-top: 10px;
}
::v-deep .el-upload{
    height: 100%;
}
</style>
  