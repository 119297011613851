<template>
<div class="dropdown-menu" :style="`top:${customMenuProp.top}px;left:${customMenuProp.left}px;`">
    <button class="menu-item" @click.stop="btnClick(item)" v-for="(item,index) in menu" :key="index">{{item.label}}</button>
</div>
</template>

  
<script>
export default {
  data() {
    return {
      menu: []
    };
  },
  methods: {
    btnClick(item) {
      if (item.value == "Copy") {
        const textarea = document.createElement("textarea");
        textarea.value = this.customMenuProp.target.target.innerText;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand("copy");
        document.body.removeChild(textarea);
        this.$message({type:"success",message:this.$t('success')})
      }
      if(item.value == "Recall"){
        this.$emit('Recall',this.customMenuProp.item)
      }
      if(item.value == "Reply"){
        this.$emit('Reply',this.customMenuProp.item)
      }
    }
  },
  created() {
    if (this.customMenuProp.item.isSelf) {
      this.menu = [
        { label: "Copy", value: "Copy" },
        { label: "Recall", value: "Recall" }
      ];
    } else {
      this.menu = [
        { label: "Reply", value: "Reply" },
        { label: "Copy", value: "Copy" }
      ];
    }
  },
  props: ["customMenuProp"]
};
</script>

  
<style scoped>
.dropdown-menu {
  display: inline-block;
  border: 1px solid #ccc;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: fixed;
}

.menu-item {
  display: block;
  padding: 8px 16px;
  border: none;
  background: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
}

.menu-item:hover {
  background-color: #f0f0f0;
}
</style>
