<template>
  <div class="loginRoot">
    <div class="login-container">
      <div class="login-box">
        <h2>{{ $t('login') }}</h2>
        <el-form :model="loginForm" @submit.native.prevent="handleLogin">
          <el-form-item>
            <el-input v-model="loginForm.userName" :placeholder="$t('account')"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="loginForm.password" type="password" :placeholder="$t('password')"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" native-type="submit">{{ $t('loginButton') }}</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { getUser } from "@/utils/api/user.js";
import { setToken,setUser } from '@/utils';
export default {
  data() {
    return {
      loginForm: {
        userName: '',
        password: ''
      }
    };
  },
  methods: {
    handleLogin() {
      // 登录逻辑
      console.log('Login form submitted:', this.loginForm);
      this.$store.dispatch("login", {
        user:this.loginForm,
        cb:()=>{
        this.$message({ type: "success", message: this.$t('loginSuccess') })
        getUser().then(r => {
          setUser(r);
          setTimeout(() => {
          this.$router.push('/index')
        }, 1000);
        });
      }
      }
    )
    }
  }
};
</script>

<style>
.loginRoot {
  height: 100vh;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #f0f2f5;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.login-box {
  width: 300px;
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  text-align: center;
}

.login-box h2 {
  margin-bottom: 20px;
  font-weight: bold;
}
</style>