<template>
    <div class="root">
        <div v-show="dialogVisible" style="padding:10px 8px;">
            <div style="display: flex;justify-content: space-between;align-items: center;">
                <el-input v-model="groupName" style="width: 155px;" :placeholder="$t('groupName')"></el-input>
                <span>{{ $t('sort') }}:</span>
                <el-input v-model="groupNum" style="width: 80px;" :placeholder="$t('sort')" type="number"></el-input>
            </div>
            <div style="display: flex;align-items: center;">
                <span style="font-size:14px;flex:1;white-space: nowrap">{{ $t('Defaultgrouping') }}：</span>
                <el-select v-model="Defaultgrouping">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
                <el-button type="primary" style="background: #15daac;color: #fff;border-color: #15daac;flex:1"
                    @click="groupSave">{{ $t('Establish') }}</el-button>
            </div>
        </div>
        <div class="title" @click="dialogVisible = true">
            <img src="@/assets/add.png" />
            <span>{{ $t('addGroup') }}</span>
        </div>
        <!-- <el-input v-model="searchQuery" style="margin:10px 0;" :placeholder="$t('search')"></el-input> -->
        <div v-for="(item, index) in users" :key="index">
            <div class="friendItem" @click="memberList(item)">
                <i class="el-icon-edit-outline" style="margin-right: 8px;"></i>
                <i class="el-icon-chat-dot-round" style="margin-right: 8px;"></i>
                <img src="@/assets/default.png" style="width: 16px;height: 16px;margin-right: 8px;">
                <span class="userName">{{ item.groupName }}</span>
                <i class="el-icon-arrow-right" v-show="!item.show"></i>
                <i class="el-icon-arrow-down" v-show="item.show"></i>
            </div>
            <div v-if="item.show">
                <div class="friendItemItem" v-for="(i, j) in groupMemList" :key="j">
                    <img :src="i.avatar" />
                    <span class="userName">{{ i.remark || i.userName }}</span>
                    <span class="time">{{ $formateDate(i.createTime) }}</span>
                </div>
            </div>
        </div>
        <!-- <el-dialog :title="$t('addGroup')" :visible.sync="dialogVisible" width="30%">
      <el-input v-model="groupNum" placeholder=""></el-input>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">{{ $t('cancel') }}</el-button>
            <el-button type="primary" @click="submit">{{ $t('confirm') }}</el-button>
        </span>
    </el-dialog> -->
    </div>
</template>

<script>
import uni from "@/utils/bus";
import { groupSave, groupList, memberList } from "@/utils/api/group"
import mixin from "@/mixin";
export default {
    mixins: [mixin],
    data() {
        return {
            searchQuery: "",
            groupName: "",
            groupNum: "",
            Defaultgrouping: false,
            query: {
                pageNum: 1,
                pageSize: 100
            },
            memQuery: {
                pageNum: 1,
                pageSize: 100,
                chatGroupId: ""
            },
            groupMemList: [],
            users: [],
            dialogVisible: false,
            options: [{ label: this.$t('no'), value: false }, { label: this.$t('yes'), value: true }]
        };
    },
    created() {
        this.groupList()
    },
    methods: {
        memberList(item) {
            item.show = !item.show
            this.memQuery.chatGroupId = item.id
            memberList(this.memQuery).then(r => {
                this.groupMemList = r.records
            })
        },
        submit() {
            groupJoin({ code: this.groupNum }).then(r => {
                this.dialogVisible = false
                this.$message({ type: "success", message: this.$t('success') })
            })
        },
        groupSave() {
            let query = {
                groupName: this.groupName,
                isDefault: this.Defaultgrouping,
                sort: this.groupNum
            }
            groupSave(query).then(r => {
                this.$message({ type: "success", message: this.$t('success') })
                this.groupList()
            })
        },
        groupList() {
            groupList(this.query).then(r => {
                this.users = r.records
                for (let i in this.users) {
                    this.$set(this.users[i], "show", false)
                }
            })
        }
    }
};
</script>

<style lang="scss" scoped>
.root {
    width: 362px;
    height: 100vh;
    position: relative;
    overflow: auto;
    background-color: #eae8e7;
    border-right: 1px solid #d1d1d1;

    .title {
        margin: 10px 0;
        display: flex;
        align-items: center;
        padding: 0 10px;
        font-weight: 700;
        font-size: 17px;
        background: none;
        cursor: pointer;

        img {
            width: 17px;
            height: 17px;
            margin-right: 8px;
        }
    }

    .friendItem {
        display: flex;
        align-items: center;
        padding: 22px 0;
        width: 100%;
        border-bottom: 2px solid #f1f1f1;
        cursor: pointer;
        box-sizing: border-box;

        .userName {
            font-size: 16px;
            font-weight: 700;
            color: #171616;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            flex: 1;
        }

        .time {
            text-align: right;
        }
    }

    .friendItemItem:hover {
        background-color: #d1d1d1;
    }

    .friendItemItem {
        display: flex;
        align-items: center;
        padding: 22px 0;
        width: 100%;
        border-bottom: 2px solid #f1f1f1;
        cursor: pointer;
        box-sizing: border-box;

        img {
            margin: 0 16px;
            width: 46px;
            height: 46px;
            border-radius: 50%;
            object-fit: cover;
        }

        .userName {
            font-size: 16px;
            font-weight: 700;
            color: #171616;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            flex: 1;
        }

        .time {
            text-align: right;
        }
    }
}
</style>
