export function setTransWhenRecieve(e){
    localStorage.setItem('recieve',e)
}
export function setTransWhenSend(e){
    localStorage.setItem('send',e)
}
export function getTransWhenRecieve(){
   return localStorage.getItem('recieve')
}
export function getTransWhenSend(){
   return localStorage.getItem('send')
}