import service from "./http";

export function autoMessage(data){
    return service.get('/chat/customer/autoMessage/page',{
        params:{
            pageNum:1,
            pageSize:100,
            tenantId:data
        }
    })
}
export function removeMessage(id){
    return service.delete(`/chat/customer/autoMessage/remove/${id}`)
}
export function saveMessage(data){
    return service.post('/chat/customer/autoMessage/save',{
        params:{
            pageNum:1,
            pageSize:100,
            tenantId:data
        }
    })
}
export function updateMessage(data){
    return service.put('/chat/customer/autoMessage/update',data)
}