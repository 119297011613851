import Vue from "vue"
import Cookies from 'js-cookie';
import uni from "./bus";
function getImg(e) {
    return require(`@/assets/${e}`)
}
export function getToken() {
    return Cookies.get("Token")
}
export function setToken(e) {
    Cookies.set("Token", e)
}
export function setUser(e) {
    try {
        localStorage.setItem("user", JSON.stringify(e))
        uni.$im.V2NIMLoginService.login(e.imAccountId, e
            .imToken, {
            "forceMode": false,
        }).then(r => {
            uni.$emit('connect')
        })
    } catch (error) {
        console.log(error)
    }
}
export function getLocalUser(e) {
    return JSON.parse(localStorage.getItem("user"))
}

export function formateDate(item) {
    if (item) {
        let month = new Date(item).getMonth() + 1
        let day = new Date(item).getDate()
        let currentMon = new Date().getMonth() + 1
        let currentDay = new Date().getDate()
        if (month == currentMon && currentDay == day)
            return (new Date(item).getHours() < 10 ? `0${new Date(item).getHours()}` :
                new Date(item).getHours()) + ":" + (new Date(item).getMinutes() <
                    10 ?
                    `0${new Date(item).getMinutes()}` : new Date(item).getMinutes())
        else
            return (month < 10 ? `0${month}` : month) + '/' + (day < 10 ? `0${day}` : day)
    } else
        return ''
}
function message(message = "success", type = "success") {
    this.$message({ type: type, message: message })
}
function copyToClipboard(e) {
    const textToCopy = e;
    navigator.clipboard.writeText(textToCopy).then(() => {
        this.$message.success('Copied to clipboard');
    }).catch(err => {
        this.$message.error('Failed to copy');
        console.error('Failed to copy: ', err);
    });
}
function gotoSession(id,type){
    uni.$emit('session',{id,type})
  }
Vue.prototype.$getImg = getImg
Vue.prototype.$gotoSession = gotoSession
Vue.prototype.$copyToClipboard = copyToClipboard
Vue.prototype.$hlmessage = message
Vue.prototype.$formateDate = formateDate
