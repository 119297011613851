var querystring = require("querystring");
import service from "../http";
var languages = require("./languages");

function translate(text, opts) {
  opts = opts || {};

  var e;
  [opts.from, opts.to].forEach(function (lang) {
    if (lang && !languages.isSupported(lang)) {
      e = new Error();
      e.code = 400;
      e.message = "The language '" + lang + "' is not supported";
    }
  });
  if (e) {
    return new Promise(function (resolve, reject) {
      reject(e);
    });
  }

  opts.from = opts.from || "auto";
  opts.to = opts.to || "en";

  opts.from = languages.getCode(opts.from);
  opts.to = languages.getCode(opts.to);

  let url = "/center/translate";
  const data = {
    client: "gtx",
    dt: "t",
    dj: 1,
    ie: "UTF-8",
    sl: opts.from,
    tl: opts.to,
    q: text,
  };
  url = `${url}?${querystring.stringify(data)}`;
  return service
    .get(url)
    .then(function (res) {
      const { sentences } = JSON.parse(res);
      let result = {};
      if (sentences && sentences[0]) {
        const { trans, orig } = sentences[0];
        result = { trans, orig };
      }
      return result;
    })
    .catch(function (err) {
      console.log("err", err);
      var e = new Error();
      if (err.statusCode !== undefined && err.statusCode !== 200) {
        e.code = "BAD_REQUEST";
      } else {
        e.code = "BAD_NETWORK";
      }
      throw e;
    });
}

export{translate,languages}
