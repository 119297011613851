import service from "./http";
// 加群
export function getGroup(nv){
    return service.get(`/chat/group/${nv}`)
}
// 上传文件
export function upload(file){
    const formData = new FormData();
    formData.append('file', file);
    return service.post(`/center/file/upload`,formData,{headers:{
        'Content-Type': 'multipart/form-data'
    }})
}
// 红包
export function sendPocket(data){
    return service.post(`/center/redpck/sendRedPackage`,data)
}

export function rodRedPackage(data){
    return service.post(`/center/redpck/rodRedPackage`,data)
}
export function updateGroup(data){
    return service.post(`/chat/group/update`,data)
}
export function aliupdate(data){
    return service.post(`/chat/group/ali/update`,data)
}
export function redpck(data){
    return service.get(`/center/redpck/${data}`)
}
// 宝箱
export function sendTreasure(data){
    return service.post(`/center/treasure/sendTreasure`,data)
}
export function rodTreasure(data){
    return service.post(`/center/treasure/rodTreasure`,data)
}
export function treasure(data){
    return service.get(`/center/treasure/${data}`)
}
