import { render, staticRenderFns } from "./recievePocket.vue?vue&type=template&id=3fa29664&scoped=true"
import script from "./recievePocket.vue?vue&type=script&lang=js"
export * from "./recievePocket.vue?vue&type=script&lang=js"
import style0 from "./recievePocket.vue?vue&type=style&index=0&id=3fa29664&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fa29664",
  null
  
)

export default component.exports