<template>
 <el-col :span="20" style="height:100%;display:flex;flex-direction:column;">
            <div style="display:flex;align-items:center;justify-content:space-between;padding:0 20px;">
                <h2>{{ title }}</h2>
                <el-switch v-model="model" :active-text="$t('Autoreplyisalsoenabledwhenonline')" v-if="current == 3"></el-switch>
            </div>
          <div class="content">
            <current1 v-if="current == 1"></current1>
            <current2 v-if="current == 2"></current2>
            <current3 v-if="current == 3"></current3>
            <current4 v-if="current == 4"></current4>
          </div>
        </el-col>
</template>
<script>
import current1 from './current1.vue'
import current2 from './current2.vue'
import current3 from './current3.vue'
import current4 from './current4.vue'
export default {
  props: ["current", "title"],
  components:{
    current1,current2,current3,current4
  },
  data(){
    return{
        model:true
    }
  }
};
</script>
<style lang="scss" scoped>
.content {
  padding: 20px;
  background: #fafbff;
  border-top: 1px solid #d9dce0;
  flex: 1;
}
.demo-form {
  max-width: 600px;
}
</style>


