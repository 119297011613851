<template>
    <div>
      <div class="rule-settings">
        <el-button type="success" style="background:#00A389" icon="el-icon-plus" @click="insertOredit()">{{ $t('newRule') }}</el-button>
        <span class="rule-note">{{ $t('ruleNote') }}</span>
        <el-table :data="tableData" stripe :header-cell-style="{'background':'rgb(255, 239, 208)','color':'black'}" border style="margin-top:32px">
          <el-table-column prop="setupBy" :label="$t('setUpBy')" align="center"></el-table-column>
          <el-table-column :label="$t('messageType')" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.type == 0">{{ $t('text') }}</span>
              <span v-if="scope.row.type == 1">{{ $t('image') }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="content" :label="$t('content')" align="center"></el-table-column>
          <el-table-column prop="time" :label="$t('replyInterval')" align="center"></el-table-column>
          <el-table-column :label="$t('operate')" align="center">
            <template slot-scope="scope">
              <el-button type="primary" @click="insertOredit(scope.row)">{{ $t('update') }}</el-button>
              <el-button type="primary" @click="deleteScope(scope.row.id)">{{ $t('delete') }}</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-dialog :title="dialogTitle" :visible.sync="centerDialogVisible" width="50%" center
      :close-on-click-modal='false'>
      <el-form ref="form" :model="insertParams" :rules="rules">
        <el-form-item :label="i.label" v-for="(i,j) in insertParamsArr" :key="j" :prop="i.prop">
          <el-radio-group v-if="i.type == 'radio'" v-model="insertParams[i.prop]">
            <el-radio :label="item.label" v-for="(item,index) in i.group" :key="index">{{item.value}}</el-radio>
          </el-radio-group>
          <el-upload v-else-if="i.type == 'image'" action="/center/file/upload" :on-success="(e)=>{insertParams[i.prop]=e.data}" :show-file-list="false">
              <el-image style="width: 50px; height: 50px" :src="insertParams[i.prop]?insertParams[i.prop]:require(`@/assets/upload.png`)"></el-image>
          </el-upload>
          <el-input v-else v-model="insertParams[i.prop]" :placeholder="i.placeholder" :type="i.inputType"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">{{ $t('cancel') }}</el-button>
        <el-button type="primary" @click="onSubmit">{{ $t('confirm') }}</el-button>
      </span>
    </el-dialog>
    </div>
  </template>
  
  <script>
import { getLocalUser } from "@/utils/index.js";
const lodash = require("lodash");
import {
  autoMessage,
  saveMessage,
  removeMessage,
  updateMessage
} from "@/utils/api/replay";
export default {
  data() {
    return {
      tableData: [],
      centerDialogVisible: false,
      dialogTitle: "",
      insertParams: {
        content: "",
        time: "",
        type: 0
      },
      rules: {
        content: [
          {
            required: true,
            message: "账号必填",
            trigger: "blur"
          }
        ],
        time: [
          {
            required: true,
            message: "金额必填",
            trigger: "blur"
          }
        ],
        type: [
          {
            required: true,
            message: "状态必填",
            trigger: "blur"
          }
        ]
      },
      insertParamsArr: [
        {
          prop: "content",
          label: "内容"
        },
        {
          prop: "time",
          label: "时间(单位：秒)"
        },
        {
          prop: "type",
          label: "类型",
          type: "radio",
          group: [{ value: "文字", label: 0 }, { value: "图片", label: 1 }]
        }
      ]
    };
  },
  methods: {
    onSubmit() {
      let param = {};
      param = this.insertParams;
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.insertParams.id == undefined) {
            saveMessage(param).then(r => {
              this.$message({
                type: "success",
                message: "提交成功！"
              });
              this.getSwiper(true);
              this.centerDialogVisible = false;
            });
          } else {
            updateMessage(param).then(r => {
              this.$message({
                type: "success",
                message: "修改成功！"
              });
              this.getRul();
              this.centerDialogVisible = false;
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    insertOredit(scope) {
      this.centerDialogVisible = true;
      this.$nextTick(() => {
        this.$refs.form.clearValidate();
      });
      if (!scope) {
        this.insertParams = {
          content: "",
          time: "",
          type: 0
        };
        this.dialogTitle = "新增";
      } else {
        this.insertParams = this.hlclone(scope);
        console.log(this.insertParams);
        this.dialogTitle = "修改";
      }
    },
    hlclone(e) {
      return lodash.cloneDeep(e);
    },
    deleteScope(id) {
      this.$alert(this.$t("deleteConfirm"), {
        confirmButtonText: this.$t("confirm"),
        callback: action => {
          if (action == "confirm") {
            removeMessage(id).then(r => {
              this.$message({ type: "success", message: this.$t("success") });
            });
          }
        }
      });
    },
    updateScope() {},
    getRul() {
      autoMessage(getLocalUser().tenantId).then(r => {
        this.tableData = r.records;
      });
    }
  },
  created() {
    this.getRul();
  },
  watch: {
    "insertParams.type": {
      handler(n) {
        if (n == 1) this.$set(this.insertParamsArr[0], "type", "image");
        else this.$set(this.insertParamsArr[0], "type", undefined);
      }
    }
  }
};
</script>
  
  <style>
.rule-settings {
  padding: 20px;
}

.rule-note {
  color: orange;
  margin-left: 10px;
}
</style>
  