import service from "./http";

export function groupJoin(data){
    return service.post('/chat/group/join',data,{
        headers:{
            "content-type":"application/x-www-form-urlencoded"
        }
    })
}

export function groupSave(data){
    return service.post('/chat/group/save',data)
}

export function groupList(data){
    return service.get('/chat/group/list',{
        params:data
    })
}

export function memberList(data){
    return service.get('/chat/group/member/list',{
        params:data
    })
}

export function memberSave(data){
    return service.post('/chat/group/member/save',data)
}