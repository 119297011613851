<template>
    <div class="requestRoot" >
      <el-row class="header">
        <el-col :span="22">
          <h2 style="display:inline">{{ $t('requestToJoinGroup') }}</h2>
          <i class="el-icon-refresh" style="font-size:1.5em;font-weight:bold;margin-left:0.5em;"></i>
        </el-col>
        <el-col :span="2">
          <el-button style="background: #00A389;border:none" type="primary" @click="checkAll">{{ $t('checkAll') }}</el-button>
        </el-col>
      </el-row>
      <el-row style="flex:1">
        <el-col :span="6" class="sidebar">
            <div v-for="(item,index) in list" :key="index" class="listItem" :class="{active:current == index}" >
                <div class="icon"><i class="el-icon-menu"></i></div>
                <span class="itemname">{{item.name}}</span>
            </div>
            <p style="text-align:center;font-size:12px;">{{$t('noMore')}}</p>
        </el-col>
        <el-col :span="18" class="main-content">
          <div class="text item">{{ $t('noMore') }}</div>
        </el-col>
      </el-row>
    </div>
  </template>
  
  <script>
import VueI18n from "vue-i18n";

export default {
  data() {
    return {
      refreshInterval: null,
      list: [{ name: this.$t("allRequest") }],
      current:0,
    };
  },
  mounted() {
    this.setRefreshInterval();
  },
  methods: {
    checkAll() {
      // 执行“Check All”操作的逻辑
    },
    refresh() {
      // 执行刷新操作的逻辑
    },
    setRefreshInterval() {
      this.refreshInterval = setInterval(this.refresh, 30000);
    }
  },
  beforeDestroy() {
    clearInterval(this.refreshInterval);
  }
};
</script>
  
  <style scoped>
.requestRoot {
  /* padding: 20px; */
  display: flex;
  flex-direction: column;
}

.header {
  border-bottom: 1px solid #d9dce0;
  padding: 20px;
}

.refresh-icon {
  text-align: right;
}

.sidebar {
  /* background-color: #f2f2f2; */
  height: 100%;
  border-right: 1px solid #d9dce0;
}

.box-card {
  margin-bottom: 20px;
}

.main-content {
  background-color: #fff;
  padding: 20px;
  border-left: 1px solid #e8e8e8;
}

.text.item {
  text-align: center;
  color: grey;
  font-size: 12px;
}
.listItem {
  padding: 20px;
  display: flex;
  cursor: pointer;
  align-items: center;
}
.icon{
    width: 50px;
    height: 50px;
    background-color: #ffad4d;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    color: white;
    border-radius: 50%;
}
.itemname{
    font-size: 18px;
    font-weight: bold;
    margin-left: 16px;
}
.active{
    background-color: #d4d4d4
}
</style>
  