<template>
    <div class="root">
        <el-input placeholder="Search" v-model="searchQuery" clearable class="input"/>
        <div class="item" v-for="(item,index) in session"  :key="index" :style="transStyle(item.conversationId)" @click="goto(item)">
            <div style="display: flex;align-items: center;">
                <img :src="item.avatar" alt="">
                <span class="name">{{ item.name }}</span>
            </div>
            <span class="time">{{ $formateDate(item.updateTime) }}</span>
        </div>
    </div>
</template>
<script>
import uni from "@/utils/bus/index.js";
import { getLocalUser } from "@/utils/index.js";
export default {
  data() {
    return {
      searchQuery: "",
      sessionId: "",
      session: [],
      item: {},
      // 外部群或用户跳转进此session中的id
      id:"",
      // 外部群或用户跳转进此session中的type
      type:""
    };
  },
  created() {
    uni.$on("getSessions", () => {
      setTimeout(() => {
        this.getSession();
      }, 500);
    });
    uni.$on("session", e => {
      this.id = e.id
      this.type = e.type
      if (e.type == "p2p")
        this.sessionId = this.$im.V2NIMConversationIdUtil.p2pConversationId(
          e.id
        );
      if (e.type == "team")
        this.sessionId = this.$im.V2NIMConversationIdUtil.teamConversationId(
          e.id
        );
    });
  },
  methods: {
    goto(item) {
      this.sessionId = item.conversationId;
      this.item = item;
    },
    transStyle(e) {
      if (e == this.sessionId) {
        return "background-color: #d1d1d1;";
      }
    },
    getSession() {
      this.$getCloudSession().then(r => {
        this.session = r;
        if (this.session.length == 0) return;
        let unreadCount = 0;
        if (this.sessionId == "") this.sessionId = r[0].conversationId;
        this.item = r[0];
        for (let i in r) {
          unreadCount = r[i].unreadCount + unreadCount;
        }
        this.$emit("unreadCount", unreadCount);
      });
    }
  },
  watch: {
    sessionId(nv) {
      let id;
      let type;
      if (nv == this.item.conversationId) {
        let item = this.item;
        let user = getLocalUser();
        if (item.type == 1) type = "p2p";
        else type = "team";
        let imAccountId = user.imAccountId;
        let messageRefer = item.lastMessage.messageRefer;
        if (messageRefer.receiverId == imAccountId) {
          id = messageRefer.senderId;
        } else {
          id = messageRefer.receiverId;
        }
        this.$clearCloudSessionUnread(item.conversationId).then(r => {});
      }
      else{
        id = this.id,
        type = this.type
      }
      this.$emit("chatroom", { id, type });
    }
  }
};
</script>
<style lang="scss" scoped>
.root {
  width: 362px;
  height: 100vh;
  background-color: #eae8e7;
  border-right: 1px solid #d1d1d1;
  overflow: auto;
  .input {
    width: 95%;
    height: 30px;
    margin: 10px 2.5%;
  }
  .item {
    cursor: pointer;
    display: flex;
    padding: 22px 10px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #f1f1f1;
    img {
      width: 46px;
      height: 46px;
      border-radius: 50%;
    }
    .name {
      max-width: 170px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-left: 16px;
      font-weight: 800;
    }
    .time {
      font-size: 12px;
      font-weight: 400;
      color: #777;
    }
  }
  .item:hover {
    background-color: #d1d1d1;
  }
}
</style>
