import service from "./http";

export function aiVoice(data){
    return service.get('/chat/business/aiVoice/page',{
        params:{
            pageNum:1,
            pageSize:100,
            tenantId:data
        }
    })
}