<template>
  <div class="user-detail-card" v-if="show">
    <el-card class="card">
      <div class="header">
        <el-tag type="danger">{{ $t('user') }}</el-tag>
        <i class="el-icon-delete-solid" style="color:#f56c6c;font-size:18px;cursor:pointer" @click="deleteFriends"></i>
      </div>
      <div class="avatar-section">
        <el-avatar class="avatar" size="large" :src="chatInfo.avatar"></el-avatar>
      </div>
      <div class="info-section">
        <div class="info-item">
          <span class="label">{{ $t('id') }}:</span>
          <span class="value">{{ chatInfo.accountId }}</span>
          <el-icon class="copy-icon">
            <el-icon-document-copy />
          </el-icon>
        </div>
        <!-- <div class="info-item">
            <span class="label">{{ $t('account') }}:</span>
            <span class="value">1000048</span>
            <el-icon class="copy-icon">
              <el-icon-document-copy />
            </el-icon>
          </div> -->
        <div class="info-item">
          <span class="label">{{ $t('nickname') }}:</span>
          <span class="value">{{ chatInfo.name }}</span>
        </div>
        <!-- <div class="info-item">
            <span class="label">{{ $t('userSource') }}:</span>
            <span class="value">PC customer s...</span>
          </div> -->
        <div class="info-item">
          <span class="label">{{ $t('lastActive') }}:</span>
          <span class="value" style="color:#409eff">{{ formateUpdateTime(chatInfo.updateTime) }}</span>
        </div>
        <div class="info-item">
          <span class="label">{{ $t('group') }}:</span>
          <el-select :placeholder="$t('selectGroup')" class="select-group" v-model="group" @change="change">
            <el-option :label="item.groupName" :value="item.id" v-for="(item,index) in users" :key="index"></el-option>
          </el-select>
        </div>
        <div class="info-item">
          <span class="label">{{ $t('remark') }}:</span>
          <el-input v-model="alias" :placeholder="$t('remarks')" class="input-remark" size="mini"
            @keyup.native.enter="setFriendInfo"></el-input>
        </div>
      </div>
      <el-button type="default" class="clear-messages" @click="clearMessages">
        {{ $t('clearMessages') }}
      </el-button>
    </el-card>
  </div>
</template>

<script>
import { delFriends } from "@/utils/api/user.js";
import { groupSave, groupList, memberSave } from "@/utils/api/group"
export default {
  props: ["show", "chatInfo", "id"],
  data() {
    return {
      alias: "",
      users: [],
      query: {
        pageNum: 1,
        pageSize: 100
      },
      group:""
    };
  },
  created() {
    this.groupList()
  },
  methods: {
    change(e){
      console.log(e)
      memberSave({accountId:this.chatInfo.accountId,chatGroupId:e,remark:this.alias}).then(r=>{
        this.$message({ type: "success", message: this.$t("success") });
      })
    },
    groupList() {
      groupList(this.query).then(r => {
        this.users = r.records
        console.log('this.users',this.users)
        // this.group = this.users[0].id
      })
    },
    clearMessages() {
      console.log("Messages cleared");
    },
    deleteFriends() {
      this.$confirm(this.$t("deletefriends"), this.$t("tips"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        type: "warning"
      }).then(() => {
        delFriends(this.id).then(r => {
          this.$message({ type: "success", message: this.$t("success") });
        });
      });
    },
    setFriendInfo() {
      this.$setFriendInfo(this.id, this.alias).then(r => {
        this.$message({ type: "success", message: this.$t("success") })
      }).catch(r => {
        this.$message({ type: "error", message: this.$t("failed") })
      })
    },
    formateUpdateTime(e) {
      if (!e) return "";
      else {
        const date = new Date(e);

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
        const seconds = String(date.getSeconds()).padStart(2, "0");

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      }
    }
  }
};
</script>

<style scoped>
.user-detail-card {
  width: 360px;
  height: 100vh;
  margin: 0 auto;
  font-size: 14px;
}

.card {
  padding: 20px;
  height: calc(100% - 40px);
  border: none;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.avatar-section {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.avatar {
  border-radius: 50%;
  height: 80px;
  width: 80px;
}

.info-section {
  margin-bottom: 20px;
}

.info-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.label {
  width: 90px;
  color: #999;
  margin-right: 5px;
}

.value {
  margin-right: 10px;
  color: #999;
}

.copy-icon {
  font-size: 16px;
  cursor: pointer;
}

.select-group {
  width: 100%;
}

.input-remark {
  width: 100%;
}

.clear-messages {
  color: red;
  display: block;
  text-align: center;
  width: 100%;
  margin-top: 20px;
}
</style>