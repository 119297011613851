<template>
<el-form label-position="left" label-width="250px" class="demo-form">
    <el-form-item :label="$t('systemLanguage')">
        <el-select style="width:100%" v-model="systemLanguage" placeholder="Select">
            <el-option v-for="item in languages" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
    </el-form-item>
    <el-form-item :label="$t('translateToWhenReceive')">
        <el-select style="width:100%" v-model="translateToWhenReceive" placeholder="Select" @change="Recieve">
            <el-option v-for="item in languages" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
    </el-form-item>
    <el-form-item :label="$t('translateToWhenSend')">
        <el-select style="width:100%" v-model="translateToWhenSend" placeholder="Select" @change="send">
            <el-option v-for="item in languages" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
    </el-form-item>
</el-form>
</template>

<script>
import {
    getTransWhenRecieve,
    setTransWhenRecieve,
    getTransWhenSend,
    setTransWhenSend
} from '@/utils/translate';
export default {
    data() {
        return {
            systemLanguage: "English",
            translateToWhenReceive: "中文",
            translateToWhenSend: "English",
            languages: [{
                    label: "English",
                    value: "en"
                },
                {
                    label: "中文",
                    value: "zh-cn"
                }
            ],
        };
    },
    methods:{
      Recieve(e){
        setTransWhenRecieve(e)
      },
      send(e){
        setTransWhenSend(e)
      }
    },
    created() {
      for(let i in this.languages){
        if(getTransWhenRecieve() == this.languages[i].value)
        this.translateToWhenReceive = this.languages[i].label
        if(getTransWhenSend() == this.languages[i].value)
        this.translateToWhenSend = this.languages[i].label
      }
    }
}
</script>
