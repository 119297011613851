<template>
    <div>
        <el-form label-position="left"  class="demo-form">
            <el-form-item :label="$t('useLanguage')">
              <el-select  v-model="systemLanguage" placeholder="Select">
                <el-option
                  v-for="item in languages"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
              <span class="language-note">{{ $t('voiceLanguageNote') }}</span>
            </el-form-item>
          </el-form>

          <h2>{{ $t('audio') }}</h2>
          <div v-for="(voice,index) in voices" :key="index" class="voice-item">
            <div class="item">
                <div style="display:flex;align-items:center;">
                <el-avatar :src="voice.avatar" size="large" style="margin-right:16px;"></el-avatar>
                <div style="display:flex;flex-direction:column;">
                    <div style="display:flex;align-items:center">
                    <div class="voice-name">{{ voice.name }}</div>
                    <el-tag>{{ voice.language }}</el-tag>
                 </div>   
                <div class="voice-info">{{ voice.info }}</div>
                </div>
                </div>
                <div>
                    <img src="@/assets/play.png" class="play" @click="playAudio(voice.file)"/>
                    <el-button class="useBtn">{{ $t('use') }}</el-button>
                </div>
            </div>
            <el-divider></el-divider>
          </div>
    </div>
</template>
<script>
  import { aiVoice } from "@/utils/api/aiVoice";
  import { getLocalUser} from "@/utils/index.js"
export default {
    data(){
        return{
      systemLanguage: "English",
      languages: [
        { label: "English", value: "English" },
        { label: "中文", value: "中文" }
      ],
            voices: [
        // { name: 'Ellen', language: 'English', info: 'There are 2 people using this voice.', avatar: 'https://i.pravatar.cc/150?img=1' },
        // { name: 'Jane', language: 'English', info: 'There are 0 people using this voice.', avatar: 'https://i.pravatar.cc/150?img=2' },
        // { name: 'Linda', language: 'English', info: 'There are 0 people using this voice.', avatar: 'https://i.pravatar.cc/150?img=3' },
        // { name: 'Manda', language: 'English', info: 'There are 0 people using this voice.', avatar: 'https://i.pravatar.cc/150?img=4' },
        // { name: 'Rose', language: 'English', info: 'There are 0 people using this voice.', avatar: 'https://i.pravatar.cc/150?img=5' },
        // { name: 'Tina', language: 'English', info: 'There are 0 people using this voice.', avatar: 'https://i.pravatar.cc/150?img=6' }
      ]
        }
    },
    methods: {
      getaiVoice(){
        aiVoice(getLocalUser().tenantId).then(r=>{
          this.voices = r.records
        })
      },
      playAudio(e){
        let audio = document.createElement('audio')
        audio.src = e
        audio.play()
      }
    },
    created(){
      this.getaiVoice()
    }
}
</script>
<style lang="scss" scoped>
.language-note {
  color: orange;
  margin-left: 10px;
}

.voice-item {
  margin-bottom: 10px;
  padding: 0 30px;
  .item{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }
  .play{
    width: 30px;
    height: 30px;
    vertical-align: middle;
    margin-right: 8px;
    cursor: pointer;
  }
}

.voice-name {
  font-size: 18px;
  font-weight: bold;
}

.voice-info {
  color: grey;
}
.titlebox{
    font-weight: 600;
    font-size: 20px;
    color: #2c303b;
    padding-left: 6px;
    line-height: 18px;
    border-left: 4px solid #00A389;
}
div ::v-deep .el-divider--horizontal{
    margin: 12px 0 !important;
}
.useBtn{
    width: 80px;
    height: 30px;
    border: 1px solid #00A389;
    box-sizing: border-box;
    border-radius: 5px;
    color: #00a389;
    cursor: pointer;
    background: transparent;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}
.el-button:hover{
    background: #00a389;
    color:white;
}
</style>

