import Vue from 'vue';
import Vuex from 'vuex';
import { login } from '@/utils/api/user';
import { setToken,setUser } from '@/utils';
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        user: {
            userName: '',
            password: ""
        },
    },
    mutations: {
        SET_USER(state, payload) {
            state.user.userName = payload.userName;
            state.user.password = payload.password;
            setToken(payload.token)
        },
        CLEAR_USER(state) {
            state.user.userName = '';
        }
    },
    actions: {
        login({ commit }, query) {
            let user = query.user
            let cb = query.cb
            user.username = user.userName + "," + user.password
            user.client_secret = user.password
            user.client_id = user.userName
            user.grant_type = "password"
            login(user).then(r => {
                user.token = r.access_token
                commit('SET_USER', user)
                cb()
            })
        },
        logout({ commit }) {
            commit('CLEAR_USER');
        }
    },
    getters: {
        userAccount: state => state.user.userName,
    },
    modules: {
        // 可以在这里添加模块
    }
});
