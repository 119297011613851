<template>
    <div class="user-card" v-if="show" @click="$emit('update:show',!show)">
      <el-card class="card">
        <div class="user-info">
          <el-avatar class="avatar" size="large" :src="currentUser.avatar"></el-avatar>
          <el-button type="success" size="mini" class="chat-button" @click="$gotoSession(currentUser.accountId,'p2p')">{{$t('Chat')}}</el-button>
        </div>
        <div class="user-details">
          <p class="username">@{{currentUser.name}}</p>
          <p class="user-id" @click="$copyToClipboard(currentUser.accountId)">{{$t('ID')}}: {{currentUser.accountId}} <i class="el-icon-copy-document"></i></p>
        </div>
        <el-button type="primary" plain size="small" class="add-friend-button" v-if="!isFriend" @click="addFriends">{{$t('Addfriend')}}</el-button>
      </el-card>
    </div>
  </template>
  
  <script>
  import uni from "@/utils/bus"
  import { addFriends } from "@/utils/api/user.js"
  export default {
    props:["show","currentUser"],
    data() {
      return {
        isFriend:false,
      };
    },
    watch:{
      currentUser:{
        handler(n){
          this.$im.V2NIMFriendService.getFriendByIds([this.currentUser.accountId]).then(r=>{
            if(r.length != 0)
            this.isFriend = true
          else
          this.isFriend = false
          })
      },
      deep:true
      }
    },
    methods:{
      addFriends(){
        addFriends({friendAccount:this.currentUser.accountId}).then(r=>{
          this.$message({type:"success",message:this.$t('addSuccess')})
          this.isFriend = true
        })
      },
    }
  };
  </script>
  
  <style scoped>
  .user-card {
    width: 240px;
    margin: 0 auto;
  }
  
  .card {
    text-align: center;
  }
  
  .user-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .avatar {
    border-radius: 50%;
    width: 80px;
    height: 80px;
  }
  
  .chat-button {
    margin-left: 10px;
  }
  
  .user-details {
    margin-top: 10px;
    text-align: left;
  }
  
  .username {
    font-weight: bold;
    margin: 0;
    color:#4983f5
  }
  
  .user-id {
    color: #888;
    margin: 0;
    font-size: 12px;
    cursor: pointer;
  }
  
  .copy-icon {
    font-size: 16px;
    cursor: pointer;
  }
  
  .add-friend-button {
    margin-top: 10px;
    width: 100%;
  }
  </style>
  