<template>
    <div class="voiceRoot" v-if="show">
        <img :src="$getImg('voice.png')" alt="">
        <span>{{ $t('voiceTip') }}</span>
    </div>
</template>
<script>
export default {
    props:["show"]
}
</script>
<style lang="scss" scoped>
.voiceRoot{
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1200;
    width: 300px;
    height: 300px;
    background: #f2f2f2;
    border-radius: 12px;
    box-sizing: border-box;
    cursor: pointer;
    flex-direction: column;
    img{
        width: 30px;
        height: 30px;
        margin-bottom: 16px
    }
}
</style>
