import service from "./http";
import { getLocalUser } from "..";
export function login(data){
    return service.get('/oauth/token',{
        params:data
    })
}
export function getUser(data){
    return service.get('/center/user/info',{
        params:data
    })
}

export function addFriends(data){
    return service.post('/chat/friend/add',{
        ...data,
        accountId:getLocalUser().accountId
    })
}

export function searchFriends(data){
    return service.get('/chat/friend/search',{
        params:{
            name:data,
            paeNum:1,
            pageSize:100
        }
    })
}

export function delFriends(data){
    return service.delete('/chat/friend/del',data)
}

export function updateUser(data){
    return service.post('/center/user/update',data)
}