<template>
    <div class="red-packet" v-if="show" @click="$emit('update:show',!show)">
      <el-card class="red-packet-card">
        <div slot="header" class="red-packet-header">
        </div>
        <div class="red-packet-body">
          <div style="font-weight:800;text-align:left">{{$t('redPacketSentBy')}} {{ query.nickName }}</div>
          <p class="red-packet-message">{{ query.textInfo }}</p>
          <div class="red-packet-points">
            <span class="points">{{ score }}</span>
            <span>{{$t('points')}}</span>
          </div>
        </div>
        <div class="red-packet-footer">
          <p>{{$t('claimed')}} {{ query.useNum }}/{{ query.packetNum }}, {{$t('total')}} {{ query.useAmount }}/{{query.totalAmount}} {{$t('Score')}}</p>
        </div>
        <div class="user-list">
          <ul>
            <li v-for="user in userList" :key="user.id">
              <img :src="user.userAvatar" alt="avatar" class="avatar">
              <span class="username">{{ user.userName }}</span>
              <span class="score">{{ user.amount }} {{$t('Score')}}</span>
            </li>
          </ul>
        </div>
      </el-card>
    </div>
  </template>
  
  <script>
import { redpck } from "@/utils/api/im.js";
import { getLocalUser } from "@/utils/index.js";
  export default {
    name: 'RedPacket',
    props: ["show","id"],
    data() {
      return {
        query:{},
        userList: [],
        scrore:""
      }
    },
    watch:{
			show(v){
				if(v){
					redpck(this.id).then(r=>{
						this.query = r
            this.userList = this.query.recordVoList
						let q = this.query.recordVoList.find(r=>{
							return r.uid == getLocalUser().imAccountId
						})
						if(q){
							this.score = q.amount
						}
					})
				}
			}
		}
  }
  </script>
  
  <style scoped>
  .red-packet {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top:0;
    background: rgba(0, 0, 0, .5);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .red-packet-card {
    border-radius: 10px;
    overflow: hidden;
    text-align: center;
    background-color: #fff;
    border: 1px solid #e5e5e5;
    width: 300px;
  }
  ::v-deep .el-card__header{
    padding: 0;
  }
  .red-packet-header {
    color: white;
    widows: 100%;
    height: 98px;
    font-size: 16px;
    background: url('../../../../assets/result_head@2x.png');
    background-size: contain;
  }
  .red-packet-body {
    padding: 20px;
  }
  .red-packet-message {
    font-size: 14px;
    color: #999;
    margin-bottom: 20px;
  }
  .red-packet-points {
    font-size: 16px;
    font-weight: bold;
    color: #ceac74;
  }
  .points{
    font-size: 32px;
  }
  .red-packet-footer {
    font-size: 12px;
    color: #999;
    border-top: 6px solid #F2F2F2;;
  }
  .user-list {
    padding: 10px 0;
    font-size: 12px;
    color: #999;
    border-top: 1px solid #ebeef5;
  }
  .user-list ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .user-list li {
    display: flex;
    align-items: center;
    margin: 10px 0;
  }
  .user-list .avatar {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 10px;
  }
  .user-list .username {
    flex-grow: 1;
    text-align: left;
  }
  .user-list .score {
    font-weight: bold;
  }
  </style>
  