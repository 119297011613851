<template>
<div style="display:flex;">
    <left_pop @chatroom="(e)=>{id=e.id,type=e.type}"></left_pop>
    <el-container style="height: 100vh;">
        <el-header class="header">
            <span>{{ chatInfo.alias || chatInfo.name }}</span>
            <div style="display: flex;align-items: center;">
                <el-switch v-model="translate" :active-text="$t('translate')">
                </el-switch>
                <el-switch style="margin-left: 16px;" v-model="hideTrans" :active-text="$t('hideTrans')">
                </el-switch>
                <img :src="$getImg('load.png')" alt="" @click="load">
                <img :src="$getImg('more.png')" @click="right_popShow=true" alt="">
            </div>
        </el-header>
        <div class="notice" v-show="type=='team'&&notice">
            <img :src="$getImg('chatnotice.png')" alt="">
            <p>{{chatInfo.groupAnnouncements}}</p>
            <img :src="$getImg('close.png')" @click="notice = false" alt="">
        </div>
        <el-main @click.native="voiceShow=false,emojiShow=false,right_popShow=false" :style="`background-image: url(${require('@/assets/chatbg@2x.png')});background-color: #efebe2;overflow: auto;`">
            <h5center :reverseArr="reverseArr" :type="type" :hideTrans="hideTrans" @Reply="Reply"></h5center>
        </el-main>
        <el-footer style="background-color: #f7f7f7;height: auto;">
            <div class="toolbar">
                <div style="position:relative">
                    <img :src="$getImg('emoji.png')" alt="" @click="toolbarOpen('emoji')">
                    <emoji :show="emojiShow" @getEmoji="getEmoji" @sendImgMess="(e)=>{send('sendTextMsg',e)}"></emoji>
                </div>
                <el-upload action="" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                    <img :src="$getImg('image.png')" alt="" @click="toolbarOpen('image')">
                </el-upload>
                <div style="position:relative">
                    <img :src="$getImg('aite.png')" v-if="type == 'team'" alt="" @click="toolbarOpen('aite')">
                    <aiteList :show="aiteListShow" :id="id" @aite="getAite"></aiteList>
                </div>
                <img :src="$getImg('screenShoot.png')" alt="" @click="toolbarOpen('screenShoot')">
                <img :src="$getImg('redPocket.png')" alt="" @click="toolbarOpen('redPocket')">
                <img :src="$getImg('treasure.png')" alt="" @click="toolbarOpen('treasure')">
                <img :src="$getImg('voice.png')" alt="" @click.stop="toolbarOpen('voice')">
            </div>
            <!-- 输入区域 -->
            <Editor style="height: 150px; overflow-y: hidden;background:white" v-model="input" :defaultConfig="editorConfig" @onCreated="onCreated" />
            <!-- <el-input resize="none" type="textarea" :rows="5" v-model="input" :placeholder="inputPlaceHolder" @keyup.enter="sendMessage"></el-input> -->
            <div style="text-align: right;background: white">
                <img :src="$getImg('send.png')" class="send" alt="" @click="send()">
            </div>
        </el-footer>
        <voice :show="voiceShow"></voice>
    </el-container>
    <!-- 发红包 -->
    <redPocket :visible.sync="redPocketShow" :id="id" :type="type"></redPocket>
    <!-- 右侧群组弹出层 -->
    <right_pop :show="right_popShow && type == 'team'" :chatInfo="chatInfo" :id="id"></right_pop>
    <!-- 右侧个人弹出层 -->
    <person :show="right_popShow && type == 'p2p'" :chatInfo="chatInfo" :id="id"></person>
    <!-- 发宝箱 -->
    <sendBaoxiang :visible.sync="baoxiangShow" :id="id" :type="type"></sendBaoxiang>
    <!-- 筛选@列表 -->
    <div v-show="false" ref="aite" v-html="input"></div>
</div>
</template>

<script>
import voice from "@/components/chatinfo/components/toolbar/voice.vue";
import emoji from "@/components/chatinfo/components/toolbar/emoji.vue";
import aiteList from "@/components/chatinfo/components//toolbar/aiteList.vue";
import redPocket from "@/components/chatinfo/components//redpocket/redPocket.vue";
import right_pop from "../right_pop/index.vue";
import person from "../right_pop/components/person.vue";
import left_pop from "../left_pop/index.vue";
import uni from "@/utils/bus/index.js";
import h5center from "./components/center.vue";
import { getGroup, upload } from "@/utils/api/im.js";
import sendBaoxiang from "@/components/chatinfo/components//baoxiang/sendBaoxiang";
import { Editor } from "@wangeditor/editor-for-vue";
import { getTransWhenSend } from "@/utils/translate";
import render from "@/components/chatinfo/render.js";
import { translate } from "@/utils/api/translate/index";
export default {
  components: {
    voice,
    emoji,
    right_pop,
    left_pop,
    h5center,
    aiteList,
    redPocket,
    sendBaoxiang,
    person,
    Editor
  },
  data() {
    return {
      input: "",
      // p2p&chatroom的id
      id: "",
      // 聊天类型
      type: "",
      chatInfo: {},
      voiceShow: false,
      emojiShow: false,
      aiteListShow: false,
      redPocketShow: false,
      right_popShow: false,
      left_popShow: false,
      path: "@/assets/",
      translate: true,
      hideTrans: true,
      messages: [],
      notice: true,
      baoxiangShow: false,
      editor: null,
      editorConfig: {
        placeholder: this.$t("translation")
      }
    };
  },
  computed: {
    reverseArr() {
      return this.messages;
    }
  },
  created() {},
  watch: {
    translate(nv) {
      this.$nextTick(() => {
        this.editorConfig.placeholder = nv
          ? this.$t("translation")
          : this.$t("untranslation");
      });
    },
    id: {
      handler(nv) {
        uni.$on("recieveMes", e => {
          if (e.receiverId == nv)
            this.$getUser(e.senderId).then(r => {
              console.log("rrrr", r);
              e.avatar = r[0].avatar;
              this.messages.push(e);
              console.log("eeeeeeeee", e);
            });
        });
        uni.$on("onMessageRevokeNotifications", e => {
          console.log("e", e);
          if (e.receiverId == nv) {
            let findIndex;
            this.reverseArr.find((r, i) => {
              if (r.senderId == e.senderId) {
                findIndex = i;
              }
            });
            this.messages.splice(findIndex, 1);
          }
        });
        if (this.type == "p2p") {
          uni.$on("connect", () => {
            this.p2pInit();
          });
          this.p2pInit();
        }
        if (this.type == "team") {
          getGroup(nv).then(r => {
            this.chatInfo = r;
            this.chatInfo.type = this.type;
            console.log("this.chatInfo", this.chatInfo);
          });
          uni.$on("connect", () => {
            this.teamInit();
          });
          this.teamInit();
        }
      },
      immediate: true
    },
    messages(newval) {
      for (let i in newval) {
        if (newval[i].messageType == 100 || newval[i].messageType == 5) {
          if (
            typeof newval[i].attachment.raw == "string" &&
            newval[i].attachment.raw.includes("{")
          ) {
            newval[i].attachment.raw = JSON.parse(newval[i].attachment.raw);
          }
        }
      }
    }
  },
  methods: {
    Reply(e) {
      console.log("hello world收到消息", e);
      this.editor.restoreSelection();
      const resume = {
        // JS 语法
        type: "reply",
        content: e.text,
        children: [
          {
            text: ""
          }
        ] // void 元素必须有一个 children ，其中只有一个空字符串，重要！！！
      };
      this.editor.insertNode(resume);
      this.editor.insertBreak();
    },
    getEmoji(e) {
      this.editor.restoreSelection();
      const resume = {
        // JS 语法
        type: "emoji",
        name: e,
        children: [
          {
            text: ""
          }
        ] // void 元素必须有一个 children ，其中只有一个空字符串，重要！！！
      };
      this.editor.insertNode(resume);
    },
    destroyEditor() {
      const editor = this.editor;
      if (editor == null) return;
      editor.destroy(); // 组件销毁时，及时销毁编辑器
    },
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
    },
    load() {
      if (this.type == "team") this.teamInit();
      if (this.type == "p2p") this.p2pInit();
    },
    // 艾特事件
    getAite(e) {
      console.log(e);
      this.editor.restoreSelection();
      const resume = {
        // JS 语法
        type: "attachment",
        name: e.name,
        id: e.id,
        children: [
          {
            text: ""
          }
        ] // void 元素必须有一个 children ，其中只有一个空字符串，重要！！！
      };
      // 检测是否重复
      let reg = `${resume.name}-${resume.id}`;
      if (this.editor.getHtml().includes(reg)) return;
      this.editor.insertNode(resume);
    },
    reguserDataArray() {
      const container = this.$refs.aite;
      const elements = container.querySelectorAll("[data-user-data]");
      const userDataArray = Array.from(elements)
        .map(el => {
          if (el.hasAttribute("data-user-data")) {
            const [name, id] = el.innerText.split("-");
            return {
              name,
              id
            };
          }
          return null;
        })
        .filter(item => item !== null);

      return userDataArray;
    },
    async send(t = "sendTextMsg", bodyParam = this.input) {
      let input = bodyParam;
      if (this.reguserDataArray().length != 0 || this.translate) {
        t = "sendCustomMsg";
        input = {
          type: 66,
          data: {
            text: this.editor.getHtml()
          }
        };
        if (this.translate) {
          let needTrans = this.editor.getText();
          await translate(input.data.text, {
            from: "auto",
            to: getTransWhenSend()
          }).then(res => {
            console.log(res);
            input.data.TransText = res.trans;
          });
          await translate(needTrans, {
            from: "auto",
            to: getTransWhenSend()
          }).then(res => {
            console.log(res);
            input.data.customTransText = res.trans;
          });
        }
      }
      console.log(input);
      if (t == "sendVideoMsg") {
        uni.showToast({
          title: "comming soon..."
        });
        return;
      }
      if (input == "") return;
      this.$sendMes(t, this.chatInfo.type, this.id, input, r => {
        this.input = "";
        this.messages.push(r.message);
      });
    },
    toolbarOpen(i) {
      switch (i) {
        case "emoji":
          this.emojiShow = !this.emojiShow;
          break;
        case "image":
          break;
        case "aite":
          this.aiteListShow = !this.aiteListShow;
          break;
        case "screenShoot":
          this.open();
          break;
        case "redPocket":
          this.redPocketShow = !this.redPocketShow;
          break;
        case "treasure":
          this.baoxiangShow = !this.baoxiangShow;
          break;
        case "voice":
          this.voiceShow = true;
          break;
      }
    },
    sendMessage() {
      if (this.input.trim() !== "") {
        this.messages.push({
          username: "Me",
          avatar: "https://example.com/my-avatar.png",
          type: "text",
          content: this.input
        });
        this.input = "";
      }
    },
    handleAvatarSuccess() {},
    beforeAvatarUpload(e) {
      this.$sendMes(
        "sendImageMsg",
        this.chatInfo.type,
        this.id,
        this.body,
        r => {
          this.input = "";
          console.log("rrrrr", r);
          this.messages.push(r.message);
          console.log(this.mesList);
        },
        e
      );
      // upload(e).then(r=>{

      // })
      return false;
    },
    open() {
      this.$message({
        message: this.$t("screenShoot"),
        type: "warning"
      });
    },
    p2pInit() {
      this.$getUser(this.id).then(r => {
        this.$getFriendByIds(this.id).then(rr => {
          this.chatInfo = r[0];
          if (rr.length != 0) {
            this.chatInfo = {
              ...this.chatInfo,
              ...rr[0]
            };
            console.log("this.chatInfo", this.chatInfo);
          } else {
            this.chatInfo.type = this.type;
            console.log("this.chatInfo", this.chatInfo);
          }
        });
      });

      this.$getHis(this.id).then(async r => {
        for (let i in r) {
          r[i].avatar = this.chatInfo.avatar;
          if (this.$im) {
            r[i].read = await this.$im.V2NIMMessageService.isPeerRead(r[i]);
          }
        }
        console.log("rrrrrr", r);
        this.messages = r.reverse();
        setTimeout(() => {
          this.scrollTop = r.length - 1;
        }, 0);
      });
      this.$im.V2NIMFriendService.checkFriend([this.id]).then(res => {
        console.log("res", res);
        this.chatInfo.status = res[this.id];
      });
    },
    teamInit() {
      this.$getHis(this.id, this.type).then(r => {
        console.log("r,,,,", r);
        this.messages = r.reverse();
        for (let i in this.messages) {
          let item = this.messages[i];
          this.$getUser(item.senderId).then(r => {
            item.avatar = r[0].avatar;
          });
        }
        setTimeout(() => {
          this.scrollTop = r.length - 1;
        }, 0);
      });
    }
  }
};
</script>

<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px;
  height: 60px;
  line-height: 60px;
  font-size: 20px;
  font-weight: 700;
  border-bottom: 1px solid #dbdbdb;
  background: white;

  img {
    width: 20px;
    height: 20px;
    margin-left: 16px;
    cursor: pointer;
  }
}

.notice {
  padding: 10px 21px 10px 9px;
  display: flex;
  align-items: center;

  img {
    width: 24px;
    height: 24px;
  }

  p {
    flex: 1;
    margin: 0 5px;
    font-weight: 400;
    font-size: 16px;
    color: #0b1f30;
    line-height: 25px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.message {
  display: flex;
  margin: 10px 0;
}

.avatar {
  margin-right: 10px;
}

.content {
  display: flex;
  flex-direction: column;
}

.username {
  font-weight: bold;
}

.text {
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
}

.image img {
  max-width: 100px;
  border-radius: 5px;
}

.toolbar {
  display: flex;
  align-items: center;
  padding: 10px 0;
  cursor: pointer;

  img {
    width: 25px;
    height: 25px;
    margin-right: 16px;
  }
}

.send {
  width: 25px;
  height: 25px;
}

::v-deep .el-textarea__inner {
  border: none;
}
</style>
