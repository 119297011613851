<template>
    <div class="treasureRoot" v-if="show" @click="$emit('update:show',!show)">
        <img src="@/assets/treasure.png" class="treasure">
        <div class="btn" @click.stop="qbx">Open</div>
    </div>
</template>

<script>
import {rodTreasure} from "@/utils/api/im"
export default {
  props: ["show","id"],
  data(){
    return{}
  },
  methods:{
    // 抢宝箱
    qbx(){
      rodTreasure({redPacketId:this.id}).then(r=>{
        this.$message({type:"success",message:this.$t('success')})
        this.$emit('update:show',!this.show)
      })
    },
  }
};
</script>

<style lang="scss" scoped>
.treasureRoot {
    position: fixed;
    top:0;
    left: 0;
    background: rgba(0,0,0, 0.5);
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000000;
    flex-direction: column;
  .treasure {
    width: 200px;
  }
  .btn{
    width: 200px;
    height: 69.5px;
    background: url("../../../../assets/opengreen@2x.png");
    background-size: contain;
    font-size: 31px;
    line-height: 31px;
    color: #fff;
    font-weight: 600;
    display:flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    background-repeat: no-repeat;
    cursor: pointer;
  }
}
</style>