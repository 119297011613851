<template>
      <el-dialog :visible.sync="dialogVisible" width="50%" :before-close="handleClose">
        <span slot="title">{{title}}</span>
        <div>
          <el-input placeholder="Search" v-model="searchQuery" clearable />
        </div>
        <el-divider></el-divider>
        <div v-if="searchQuery" style="max-height:300px;overflow: auto;">
          <el-row :key="index" v-for="(item,index) in friendsList" class="friend-row">
            <el-col :span="4">
              <el-avatar :src="item.avatar" size="large"></el-avatar>
            </el-col>
            <el-col :span="16">
              <span class="friend-name">{{ item.nickName }}</span>
            </el-col>
            <el-col :span="4">
              <el-button type="primary" v-if="item.isFriend" @click="addFriend(item)">{{ $t('add') }}</el-button>
              <el-button type="primary" v-else @click="$gotoSession(item.imAccountId,'p2p')">{{ $t('sendMessage') }}</el-button>
            </el-col>
          </el-row>
        </div>
        <div v-else>
          <p>{{ $t('noResults') }}</p>
        </div>
      </el-dialog>
  </template>
  
  <script>
  import { addFriends,searchFriends } from '@/utils/api/user';
  export default {
    data() {
      return {
        searchQuery: '',
        friendsList:[[{
          id: 1,
          name: 'Samuel Ycpj',
          avatar: 'https://i.pravatar.cc/150?img=1'
        }]]
      };
    },
    props:["dialogVisible","title"],
    watch:{
      searchQuery(nv){
        searchFriends(nv).then(r=>{
          this.friendsList = r.records
          if(this.friendsList && this.friendsList.length){
						this.loading = true
						let arr = []
						for(let i in this.friendsList){
							arr.push(this.friendsList[i].imAccountId)
						}
						this.$im.V2NIMFriendService.getFriendByIds([...arr]).then(r=>{
							for(let i in r){
								for(let j in this.friendsList){
									if(this.friendsList[j].imAccountId == r[i].accountId)
									this.friendsList[j].isFriend = true
								}
							}
							console.log(this.friendsList)
						});
					}
        })
      }
    },
    methods: {
      handleClose() {
        this.$emit('update:dialogVisible',!this.dialogVisible)
      },
      addFriend(friend) {
        // 添加好友的逻辑
        console.log(`Added friend: ${friend.name}`);
      }
    }
  };
  </script>
  
  <style>
  .friend-row {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
  }
  
  .friend-name {
    font-size: 18px;
    margin-left: 10px;
  }
  
  .el-divider {
    margin: 10px 0;
  }
  </style>
  