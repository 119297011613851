import createI18n from 'vue-i18n';
import Vue from 'vue';
// 导入语言文件
import en from './en.json';
import zh from './zh.json';
Vue.use(createI18n)
const messages = {
  en,
  zh
};

// 创建 i18n 实例
const i18n = new createI18n({
  locale: 'en', // 设置默认语言
  fallbackLocale: 'en', // 设置回退语言
  messages, // 设置语言文件
});

export default i18n;