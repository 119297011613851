<template>
<div class="emoji_list" v-if="show">
    <div v-if="emoji" class="content">
        <div v-for="(item,index) in 162" :key="index" style="display:flex;height:100%;width:100%;align-items:center;justify-content:center;" @click="getEmoji(index)">
            <img class="emojiitem" :src="`static/emojiList/emoji${index + 1}.png`" style="width:50%;" />
        </div>
    </div>
    <div v-else class="content custom">
        <el-upload action="" style="display: flex;width: 100%;height:100%;align-items: center;justify-content: center" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
            <i class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <img :src="item" v-for="(item,index) in customList" style="width: 100%;height:100%" :key="index" alt="" @click="sendImgMess(item)">
    </div>
    <div class="bottom">
        <div style="display:flex;height:100%;">
            <div :style="isEmoji(true)" @click="emoji = true">
                <img :src="$getImg('emoji@2x.png')" />
            </div>
            <div :style="isEmoji(false)" @click="emoji = false">
                <img :src="$getImg('custom@2x.png')" />
            </div>
        </div>
        <img :src="$getImg('setting@2x.png')" />
    </div>
</div>
</template>

<script>
import { upload } from "@/utils/api/im"
export default {
    props: ["show"],
    data() {
        return {
            emoji: true,
            customList:[]
        };
    },
    created() {
			this.customList = JSON.parse(localStorage.getItem('customList')) || []
		},
    methods: {
        isEmoji(flag = true) {
            let style = `width:50px;display:flex;justify-content:center;align-items:center;height:100%;`;
            let back = "";
            if (flag) {
                if (this.emoji) {
                    back = "background:white;";
                }
            }
            if (flag == false) {
                if (this.emoji == false) {
                    back = "background:white;";
                }
            }
            return style + back;
        },
        getEmoji(i) {
            this.$emit('getEmoji', `emoji${i+1}`)
        },
        sendImgMess(item){
				let html = `<img src='${item}' style='width:100px'/>`
        console.log(item)
				this.$emit('sendImgMess',html)
			},
        handleAvatarSuccess() {},
        beforeAvatarUpload(e) {
          upload(e).then(r=>{
						this.$message({type:'success', message:'Upload Successfully!'})
						this.customList.push(r)
						localStorage.setItem('customList',JSON.stringify(this.customList))
          })
          return false
        },
    }
};
</script>

<style lang="scss" scoped>
.emoji_list {
    position: absolute;
    bottom: 50px;
    z-index: 1001;
    padding: 30px 0 0;
    width: 500px;
    max-height: 400px;
    background: #fff;
    text-align: left;
    overflow: auto;
    cursor: pointer;
    border-radius: 10px 10px 0 0;
    box-shadow: 0 0 5px 5px #0000001a;
    box-sizing: border-box;

    .bottom {
        display: flex;
        align-items: center;
        padding: 0 18px;
        position: sticky;
        bottom: 0;
        width: 100%;
        height: 53px;
        z-index: 1000;
        cursor: pointer;
        box-sizing: border-box;
        background: #f5f6f7;
        justify-content: space-between;

        img {
            width: 36px;
            height: 36px;
        }
    }
}

.avatar-uploader {
    width: 88px;
    height: 88px;
}

.content {
    padding: 0 20px;
    display: grid;
    grid-template-rows: repeat(24, 60px);
    grid-template-columns: repeat(auto-fill, 60px);
    align-items: center;
}

.custom {
    display: grid;
    grid-template-columns: repeat(auto-fill, 88px);
    grid-template-rows: repeat(auto-fill, 88px);
    justify-content: space-evenly;
    grid-row-gap: 30px;
    grid-column-gap: 20px;
}

.avatar-uploader ::v-deep .el-upload {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid rgb(199, 199, 199);
    border-radius: 5px;
}

.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    text-align: center;
}
</style>
