<template>
    <div v-if="show" class="recievePocket" @click="closePopup">
	<div :style="{'width':'300px',height:'495px', 'background-color': 'rgb(224, 85, 75)',
  'border-radius': '14px'}">
		<div class="popup-content">
			<div style="padding: 0 40px;flex:1;padding-top: 150px;background: rgb(224, 85, 75);">
				<div class="header">
					<img :src="query.userAvatar" class="user-avatar" />
					<span class="user-name">{{ query.nickName }}</span>
				</div>
				<div class="message">
					<span>{{ query.textInfo }}</span>
				</div>
			</div>
			<div :style="`flex-basis: 30%;background: url(${require('@/assets/cover_bg@2x.png')});background-size: cover;`">
				<button class="open-button" @click.stop="openDetails" :class="{loading:loading}">Open</button>
				<span class="view-details" @click.stop="views">view details ></span>
			</div>
		</div>
	</div>
</div>
</template>

<script>
import { sendPocket,redpck,rodRedPackage } from "@/utils/api/im.js";
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    id: {}
  },
  data() {
    return {
      query: {},
      loading: false
    };
  },
  watch: {
    show(val) {
      this.loading = false;
      if (val) {
        redpck(this.id).then(r => {
          this.query = r;
        });
      }
    }
  },
  methods: {
    views() {
      this.$emit("update:show", false);
      this.$emit("views");
    },
    closePopup() {
      this.$emit("update:show", false);
    },
    openDetails() {
      this.loading = true;
      rodRedPackage({ redPacketId: this.id })
        .then(r => {
            this.$emit("update:show", false);
            this.$emit("views");
        })
    },
    viewDetails() {
      this.$emit("update:show", false);
      this.$emit("views");
    }
  }
};
</script>

<style scoped>
.recievePocket {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.popup-content {
  text-align: center;
  color: #ffeab8;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.user-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.user-name {
  margin-left: 10px;
  font-size: 18px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.message {
  font-size: 16px;
  margin-bottom: 20px;
}

.open-button {
  background-color: #ebcd9a;
  border-radius: 50%;
  height: 100px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 1.6rem;
  border: none;
  margin: 0 auto;
}

.view-details {
  font-size: 14px;
  color: #ffeab8;
}
@keyframes loading {
  from {
    transform: rotateY(0deg);
    background-color: red !important;
  }
  to {
    transform: rotateY(360deg);
    background-color: magenta !important;
  }
}
.loading {
  animation: loading 2s linear infinite;
  position: relative;
}
</style>