import { h } from "snabbdom"
import { Boot } from '@wangeditor/editor'
import { DomEditor } from '@wangeditor/editor'
function withAttachment(editor) {                        // JS 语法
    const { isInline, isVoid } = editor
    const newEditor = editor

    newEditor.isInline = elem => {
        const type = DomEditor.getNodeType(elem)
        if (type === 'attachment' || type === 'emoji' || type === "reply") return true // 针对 type: attachment ，设置为 inline
        return isInline(elem)
    }

    newEditor.isVoid = elem => {
        const type = DomEditor.getNodeType(elem)
        if (type === 'attachment' || type === 'emoji' || type === "reply") return true // 针对 type: attachment ，设置为 void
        return isVoid(elem)
    }

    return newEditor // 返回 newEditor ，重要！！！
}


// @事件
function renderAttachment(elem, children, editor) {                                                // JS 语法

    const { name = '', id = '' } = elem

    const attachVnode = h(
        'span',
        {
            props: { contentEditable: false }, // HTML 属性，驼峰式写法
            attrs: { "data-prop": `${name}-${id}` },
            style: { display: 'inline-block', marginLeft: '3px', marginRight: '3px', color: '#3669de' },
        },
        // 子节点
        [`@${name}`]
    )

    return attachVnode
}
const renderElemConf = {
    type: 'attachment', // 新元素 type ，重要！！！
    renderElem: renderAttachment,
}
Boot.registerRenderElem(renderElemConf)

// emoji事件

function renderEmoji(elem, children, editor) {                                                // JS 语法

    const { name = '' } = elem

    const attachVnode = h(
        'img',
        {
            props: { contentEditable: false }, // HTML 属性，驼峰式写法
            attrs: { "data-prop": `${name}`, src: `./static/emojiList/${name}.png` },
            style: { display: 'inline-block', marginLeft: '3px', marginRight: '3px', width: '30px', height: '30px' },
        },
        // 子节点
        []
    )

    return attachVnode
}
const renderElemEmoji = {
    type: 'emoji', // 新元素 type ，重要！！！
    renderElem: renderEmoji,
}
Boot.registerRenderElem(renderElemEmoji)

// // 回复事件

function renderReply(elem, children, editor) {                                                // JS 语法

    const { content = '' } = elem

    const attachVnode = h(
        'span',
        {
            props: { contentEditable: false }, // HTML 属性，驼峰式写法
            // attrs: { innerHtml: text },
            style: { display: 'inline-flex', maxWidth: '150px', maxHeight: '50px',alignItems:"center",justifyContent:"center",
                background:"#e8e8e8",color:"#797979",padding:"5px",overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap",margin:"0 3px"},
        },
        // 子节点
        [content]
    )

    return attachVnode
}
const renderElemReply = {
    type: 'reply', // 新元素 type ，重要！！！
    renderElem: renderReply,
}
Boot.registerRenderElem(renderElemReply)


// 输出@
function attachmentToHtml(elem, childrenHtml) {                             // JS 语法
    const { name = '', id = '' } = elem
    const html = `<span
            data-w-e-type="attachment"
            data-w-e-is-void
            data-w-e-is-inline
            data-user-data="${name}-${id}"
            style="margin:0 3px;color:#3669de"
        >@${name}</span>`

    return html
}
const elemToHtmlConf = {
    type: 'attachment', // 新元素的 type ，重要！！！
    elemToHtml: attachmentToHtml,
}
Boot.registerElemToHtml(elemToHtmlConf)

//   输出emoji
function emojiToHtml(elem, childrenHtml) {                             // JS 语法
    const { name = '' } = elem
    const html = `<img
            data-w-e-type="attachment"
            data-w-e-is-void
            data-w-e-is-inline
            data-emoji-data="${name}"
            src="./static/emojiList/${name}.png"
            style="margin:0 3px;width:30px;height:30px"/
        >`

    return html
}
const elemToHtmlEmoji = {
    type: 'emoji', // 新元素的 type ，重要！！！
    elemToHtml: emojiToHtml,
}
Boot.registerElemToHtml(elemToHtmlEmoji)

//   输出回复
function replyToHtml(elem, childrenHtml) {                             // JS 语法
    const { content = '' } = elem
    const html = `<div style="display:flex;max-width:150px;max-height:50px;
    align-items:center;justify-content:center;
    background:#e8e8e8;color:#797979;
    padding:5px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap">${content}</div>`
    return html
}
const elemToHtmlReply = {
    type: 'reply', // 新元素的 type ，重要！！！
    elemToHtml: replyToHtml,
}
Boot.registerElemToHtml(elemToHtmlReply)

//   注册总插件事件
Boot.registerPlugin(withAttachment)