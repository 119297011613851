<template>
    <div class="root" >
        <div class="title"  @click="dialogVisible = true">
            <img src="@/assets/add.png"/>
            <span>{{$t('addFriends')}}</span>
         </div>
         <el-input v-model="searchQuery" style="margin:10px 0;" :placeholder="$t('search')"></el-input>
         <div class="friendItem" v-for="(item,index) in filteredUsers" :key="index" @click="$gotoSession(item.accountId,'p2p')">
            <img v-if="item.userProfile" :src="item.userProfile.avatar"/>
            <span class="userName" v-if="item.userProfile">{{item.alias || item.userProfile.name}}</span>
            <span class="time">{{$formateDate(item.updateTime)}}</span>
         </div>
         <addFriends :title="$t('addFriends')" :dialogVisible.sync="dialogVisible"></addFriends>
    </div>
</template>
<script>
import addFriends from './components/addFriends.vue'
import uni from "@/utils/bus"
import mixin from '@/mixin/index.js'
export default {
  mixins:[mixin],
  data() {
    return {
      searchQuery: "",
      users:[],
      dialogVisible:false,
    };
  },
  components:{
    addFriends
  },
  created(){
    uni.$once("getFriends", e => {
				this.$getFriends().then(r => {
					this.users = r
					console.log(r)
				})
			})
			this.$getFriends().then(r => {
				this.users = r
				console.log(r)
			})
  },
  methods:{
  }
};
</script>
<style lang="scss" scoped>
.root {
  width: 362px;
  height: 100vh;
  position: relative;
  overflow: auto;
  background-color: #eae8e7;
  border-right: 1px solid #d1d1d1;
  .title {
    margin: 10px 0;
    display: flex;
    align-items: center;
    padding: 0 10px;
    font-weight: 700;
    font-size: 17px;
    background: none;
    cursor: pointer;
    img {
      width: 17px;
      height: 17px;
      margin-right: 8px;
    }
  }
  .friendItem {
    display: flex;
    align-items: center;
    padding: 22px 0;
    width: 100%;
    border-bottom: 2px solid #f1f1f1;
    cursor: pointer;
    box-sizing: border-box;
    img {
      margin: 0 16px;
      width: 46px;
      height: 46px;
      border-radius: 50%;
      object-fit: cover;
    }
    .userName {
      font-size: 16px;
      font-weight: 700;
      color: #171616;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      flex:1;
    }
    .time{
        text-align: right;
    }
  }
  .friendItem:hover {
    background-color: #d1d1d1;
  }
}
</style>
