const mixin = {
  computed: {
    filteredUsers() {
      if (this.users.length == 0)
        return
      if (this.users[0].name)
        return this.users.filter(user =>
          user.name.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      else {
        return this.users.filter(user =>
          user.userProfile.name.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      }
    }
  }
}
export default mixin