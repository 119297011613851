import axios from 'axios';
import { Message } from 'element-ui';
import { getToken } from '..';
// 创建一个 Axios 实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // API的基本URL
  timeout: 6000 // 请求超时时间
});

// 请求拦截器
service.interceptors.request.use(
  config => {
    config.headers['Authorization'] = 'Bearer ' + getToken();
    return config;
  },
  error => {
    // 对请求错误做些什么
    console.error(error); // for debug
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  response => {
    if(response.config.url.includes('translate_a'))
      return response
    // 对响应数据做些什么
    const res = response.data;
    // if (res.code !== 20000) {
    //   // 这里可以处理一些全局的错误信息，比如token失效等
    //   return Promise.reject(new Error(res.message || 'Error'));
    // } else {
    //   return res;
    // }
      if (res.code != 200 && response.config.url != "/oauth/token") {
        Message.error(res.msg || 'failed')
        return Promise.reject(res);
      }
      else if(response.config.url == "/oauth/token"){
        return res
      }
      else
        return res.data
  },
  error => {
    // 对响应错误做些什么
    console.error('.............'); // for debug
    console.error(error); // for debug
    Message.error(error.response.data.msg || error.message || "failed")
    if (error.response.data.code == 96008) {
      setTimeout(() => {
        window.location.href = "/"
      }, 1000);
    }
    return Promise.reject(error);
  }
);

export default service;
